import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spinner, Alert, Button, Modal, Row, Col } from "react-bootstrap";
import { FaPlus, FaTrash } from "react-icons/fa";
import { jwtDecode } from "jwt-decode"; // Import jwtDecode to decode the token
import path from "../config";

const ScheduleInfo = ({ scheduleId }) => {
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [isAnalgesicPlan, setIsAnalgesicPlan] = React.useState(false); // Valor inicial "false"

  const token = localStorage.getItem("userToken");
  const decodedToken = jwtDecode(token);
  const Role = decodedToken.role; // Adjust according to your token structure


  const allowedTypes = [
    "application/msword", // .doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    "application/vnd.ms-excel", // .xls
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    "application/pdf", // .pdf
    "text/plain", // .txt
  ];

  const fetchFiles = async () => {
    try {
      const filesResponse = await axios.get(
        path + `files/schedulefiles/${scheduleId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      setFiles(filesResponse.data.files);
    } catch (error) {
      console.error("Error fetching schedule:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [scheduleId]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
        setUploadError(null); // Limpa qualquer erro anterior
      } else {
        setSelectedFile(null);
        setUploadError("Apenas ficheiros Word, Excel, PDF, e TXT são permitidos.");
      }
    }
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();
    setUploadError(null); // Reset upload error before each upload attempt
    const formData = new FormData();
    formData.append("selectedFile", selectedFile);
    formData.append("analgesicPlan", isAnalgesicPlan); // Adiciona o estado do plano analgésico

    console.log(isAnalgesicPlan)

    try {
      await axios.post(
        path + `files/uploadfile/${scheduleId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      setShowModal(false);
      fetchFiles();
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadError("Falha ao carregar o ficheiro.");
    }
  };

  const handleFileDownload = async (fileId, fileName) => {
    try {
      const response = await axios.get(
        path + `files/getFileById/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
          responseType: "blob",
        }
      );

      // Cria um link para acionar o download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const confirmDeleteFile = (file) => {
    setFileToDelete(file);
    setConfirmDeleteModal(true);
  };

  const handleFileDelete = async () => {
    if (!fileToDelete) return;
    try {
      await axios.delete(
        path + `files/deleteFile/${fileToDelete.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      setConfirmDeleteModal(false);
      fetchFiles();
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status" />
        <span className="ms-2">A ler os detalhes da marcação...</span>
      </div>
    );
  }

  const handleShowModal = () => setShowModal(true);

  return (
    <div className="container">
      <h2 className="mb-4">
        Documentos{" "}
        <FaPlus
          style={{ cursor: "pointer", marginLeft: "10px" }}
          onClick={handleShowModal}
          className="dscolor"
        />
      </h2>
      <div>
        {/* Documentos com Plano Analgésico */}
        {files.length > 0 ? (
          <div>
            {/* Documentos com Plano Analgésico */}
            <h5 style={{ marginTop: "20px" }}>Plano Analgésico</h5>
            {files.filter((file) => file.analgesicPlan === true).length > 0 ? (
              files
                .filter((file) => file.analgesicPlan === true)
                .map((file) => (
                  <div
                    key={file.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "5px",
                      padding: "5px 0",
                    }}
                  >
                    <button
                      onClick={() => handleFileDownload(file.id, file.OriginalName)}
                      style={{
                        border: "none",
                        background: "none",
                        color: "#007bff",
                        cursor: "pointer",
                        textAlign: "left",
                        flex: 1,
                        marginRight: "10px",
                        padding: "0",
                      }}
                    >
                      {file.OriginalName}
                    </button>
                    <FaTrash
                      onClick={() => confirmDeleteFile(file)}
                      style={{
                        border: "none",
                        background: "none",
                        color: "#dc3545",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                ))
            ) : (
              <p>Sem documentos para Plano Analgésico.</p>
            )}

            {/* Outros Documentos */}
            <h5 style={{ marginTop: "20px" }}>Outros</h5>
            {files.filter((file) => file.analgesicPlan === false).length > 0 ? (
              files.filter((file) => file.analgesicPlan === false).map((file) => (
                <div
                  key={file.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "5px",
                    padding: "5px 0",
                  }}
                >
                  <button
                    onClick={() => handleFileDownload(file.id, file.OriginalName)}
                    style={{
                      border: "none",
                      background: "none",
                      color: "#007bff",
                      cursor: "pointer",
                      textAlign: "left",
                      flex: 1,
                      marginRight: "10px",
                      padding: "0",
                    }}
                  >
                    {file.OriginalName}
                  </button>
                  <FaTrash
                    onClick={() => confirmDeleteFile(file)}
                    style={{
                      border: "none",
                      background: "none",
                      color: "#dc3545",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ))
            ) : (
              <p>Sem outros documentos carregados.</p>
            )}
          </div>
        ) : (
          <Alert variant="info">Sem ficheiros carregados.</Alert>
        )}
      </div>

      {/* Upload Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Carregar ficheiro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFileUpload}>
            {/* Input para selecionar arquivo */}
            <div className="mb-3">
              <label htmlFor="fileInput" className="form-label">
                Escolher ficheiro
              </label>
              <input
                type="file"
                id="fileInput"
                name="selectedFile"
                onChange={handleFileChange}
                required
                className="form-control"
              />
            </div>

            {/* Campo de seleção para Plano Analgésico */}
            {Role === "Anesthetist" &&(
            <div className="mb-4">
              <Row className="align-items-center">
                <Col className="d-flex align-items-center">
                  <label htmlFor="analgesicPlan" className="form-label mb-0">
                    Este ficheiro é um Plano Analgésico?
                  </label>
                  <div className="form-check mb-0 ms-2"> {/* ms-2 adiciona um espaçamento à direita do texto */}
                    <input
                      type="checkbox"
                      id="analgesicPlan"
                      name="analgesicPlan"
                      checked={isAnalgesicPlan} // Acompanhar o estado do checkbox (true ou false)
                      onChange={(e) => setIsAnalgesicPlan(e.target.checked)} // Atualiza o estado com o valor do checkbox
                      className="form-check-input custom-checkbox" // Adiciona a classe personalizada para cor
                    />
                  </div>
                </Col>
              </Row>
            </div>
            )}

            {/* Mensagem de erro */}
            {uploadError && (
              <Alert variant="danger" className="mt-2">
                {uploadError}
              </Alert>
            )}

            {/* Botões */}
            <div style={{ textAlign: "right" }} className="mt-2">
              <Button
                variant="secondary"
                onClick={() => setShowModal(false)}
                className="me-2"
              >
                Cancelar
              </Button>
              <Button type="submit" variant="primary" disabled={!selectedFile}>
                Carregar
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Confirm Delete Modal */}
      <Modal show={confirmDeleteModal} onHide={() => setConfirmDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza de que deseja apagar o ficheiro{" "}
          <strong>{fileToDelete?.OriginalName}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmDeleteModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleFileDelete}>
            Apagar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ScheduleInfo;
