// src/components/Login.js
import React, { useState } from "react";
import {Form, Button, Container, Row, Col, Card, Image} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { jwtDecode } from "jwt-decode"; // Import jwtDecode to decode the token
import "../CSS/Login.css";
import path from "../config";

function Login({ onLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // To store error messages
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await fetch(path + "api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log("Login successful", data);
        localStorage.setItem("userToken", data.token);

        const decodedToken = jwtDecode(data.token);

        if (!decodedToken.passwordReseted) {
          navigate("/ChangePassword"); // Redirect to change password screen
        } else {
          onLogin({
            isAuthenticated: true,
            role: decodedToken.role,
            passwordReseted: decodedToken.passwordReseted,
            username: decodedToken.username,
            firstName: decodedToken.username.split(" ")[0],
            conditionsSigned: decodedToken.conditionsSigned,
            profileCompleted: decodedToken.profileCompleted,
          });
          if (decodedToken.role === "Client") {
            if (decodedToken.conditionsSigned === true ) {
              navigate("/dashboardclient"); // Redirect to client dashboard
            } 
            else {
              navigate("/useragreement"); // Redirect to client dashboard
            }
          } else if (decodedToken.role === "Anesthetist") {
            if(decodedToken.conditionsSigned === true && decodedToken.profileCompleted === true) {
              navigate("/dashboardanesthetist"); // Redirect to client dashboard
            } 
            else {
              navigate("/useragreement"); // Redirect to client dashboard
            }
          } else {
            navigate("/dashboard"); // Redirect to the regular dashboard
          }
        }
      } else {
        setError(data.message || "Falha no login, tente novamente");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setError("An error occurred: " + error.message);
    }
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center align-items-center">
        <Col md={6} className="d-none d-md-block d-flex flex-wrap">
          <Image src="./assets/camera.png" fluid alt="description of image" />
        </Col>
        <Col md={6}>
          <Card className="p-4 shadow-lg border-0 rounded-3">
            <Card.Body>
              <h2 className="mb-4 fw-bold textVet">Bem-vindo</h2>
              {error && <p className="text-danger text-center">{error}</p>}{" "}
              {/* Display error message */}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="rounded px-3 py-2"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="mb-3">
                  <Form.Label>Palavra-Passe</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Palavra-passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="rounded px-3 py-2"
                  />
                  <div className="text-end mt-3" style={{ fontSize: "0.9em" }}>
                    <Link to="/RecoverPassword">Esqueceu a palavra-passe?</Link>
                  </div>
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Entrar
                </Button>
              </Form>
              <div className="text-center mt-3" style={{ fontSize: "0.9em" }}>
                Não tem uma conta? <Link to="/register">Registar</Link>{" "}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
