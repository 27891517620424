import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col, Card, Spinner, Form, ListGroup, Pagination, Button, Modal } from "react-bootstrap";
import { FaInfoCircle, FaStickyNote, FaClipboard } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import path from "../config";
import ScheduleNotes from "../Scheduler/ViewNotes"; // Import the ScheduleNotes component
import FileDownloadButton from "../Files/FileDownloadButton";
import Swal from "sweetalert2"; // Import SweetAlert2

function Marcacoes() {
    const [marcacoes, setMarcacoes] = useState([]);
    const [filteredMarcacoes, setFilteredMarcacoes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(4); // Número de cards por página
    const [dataInicial, setDataInicial] = useState(localStorage.getItem("dataInicial") || ""); // Retrieve saved value
    const [dataFinal, setDataFinal] = useState(localStorage.getItem("dataFinal") || ""); // Retrieve saved value
    const [statusFilter, setStatusFilter] = useState(localStorage.getItem("statusFilter") || "Todos");
    const [showModal, setShowModal] = useState(false);
    const [selectedScheduleId, setSelectedScheduleId] = useState(null); // New state for scheduleId


    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem("dataInicial", dataInicial); // Save dataInicial to localStorage
        localStorage.setItem("dataFinal", dataFinal); // Save dataFinal to localStorage
        localStorage.setItem("statusFilter", statusFilter); // Save statusFilter to localStorage
    }, [dataInicial, dataFinal, statusFilter]);

    // Função para buscar marcações
    const fetchMarcacoes = async () => {
        if (!dataInicial || !dataFinal) {
            return; // Retorna se as datas não forem preenchidas
        }

        setLoading(true);
        try {
            const response = await fetch(
                path + `sche/GetAnesthetistEventsInRange?startDate=${dataInicial}&endDate=${dataFinal}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Erro ao buscar marcações");
            }

            const data = await response.json();
            setMarcacoes(data);
            setFilteredMarcacoes(data);
            setCurrentPage(1); // Reiniciar para a primeira página
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    // Filtrar por status
    useEffect(() => {
        const fetchUserProfileStatus = async () => {
            try {
              const token = localStorage.getItem("userToken");
              if (!token) {
                throw new Error("Token não encontrado");
              }
          
              // Decodificando o token
              const decodedToken = jwtDecode(token); 
          
              // Verificando o valor de profileCompleted no token
              if (decodedToken.profileCompleted === false) {
                // Fazendo a requisição apenas se profileCompleted for false
                const response = await fetch(path + "user/getusercompleted", {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                });
          
                if (!response.ok) {
                  throw new Error("Falha ao verificar status do perfil");
                }
          
                const data = await response.json();
          
                // Verificando novamente o status do perfil, caso precise de garantir a consistência
                if (data.profileCompleted === false) {
                  Swal.fire({
                    title: "Conclua o seu perfil",
                    text: "Conclua o seu perfil para ter total acesso à plataforma",
                    icon: "warning",
                    confirmButtonText: "Ok",
                  }).then(() => {
                    navigate("/MyProfile");
                  });
                }
              } else {
                // Se o profileCompleted for true, você pode continuar sem fazer a chamada
                console.log("Perfil já completo.");
              }
            } catch (error) {
              console.error("Erro ao verificar o status do perfil:", error);
            }
          };

        let filtered = marcacoes;

        if (statusFilter === "Marcado") {
            filtered = marcacoes.filter((event) => event.associatedwithuserId);
        } else if (statusFilter === "Disponivel") {
            filtered = marcacoes.filter((event) => !event.associatedwithuserId);
        }

        fetchUserProfileStatus();
        setFilteredMarcacoes(filtered);
        setCurrentPage(1); // Reiniciar para a primeira página
    }, [statusFilter, marcacoes]);


    // Filtrar por intervalo de datas
    const handleFilter = () => {
        fetchMarcacoes();
    };

    // Paginação
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredMarcacoes.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredMarcacoes.length / itemsPerPage);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const handleShowModal = (scheduleId) => {
        setSelectedScheduleId(scheduleId); // Set the selected schedule ID
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedScheduleId(null);
    };

    if (loading) {
        return <Spinner animation="border" />;
    }

    return (
        <div className="container mt-4">
            <h2 className="fw-bold textVet">Marcações</h2>

            {/* Filtro por intervalo de datas */}
            <Form className="mb-4">
                <Row>
                    <Col md={4}>
                        <Form.Group controlId="dataInicial">
                            <Form.Label>Data Inicial:</Form.Label>
                            <Form.Control
                                type="date"
                                value={dataInicial}
                                onChange={(e) => setDataInicial(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="dataFinal">
                            <Form.Label>Data Final:</Form.Label>
                            <Form.Control
                                type="date"
                                value={dataFinal}
                                onChange={(e) => setDataFinal(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Form.Group controlId="statusFilter">
                            <Form.Label>Status:</Form.Label>
                            <Form.Select
                                value={statusFilter}
                                onChange={(e) => setStatusFilter(e.target.value)}
                            >
                                <option value="Todos">Todos</option>
                                <option value="Disponivel">Disponivel</option>
                                <option value="Marcado">Marcado</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={2} className="d-flex align-items-end">
                        <button
                            className="btn btn-primary w-100"
                            onClick={handleFilter}
                            disabled={!dataInicial || !dataFinal}
                        >
                            Filtrar
                        </button>
                    </Col>
                </Row>
            </Form>

            {/* Cards das marcações */}
            <Row className="g-4">
                <ListGroup>
                    {currentItems.length > 0 ? (
                        <Row className="g-3">
                            {currentItems.map((event) => (
                                <Col sm={6} md={4} lg={3} key={event.availabilityId}>
                                    <Card className="shadow-sm border-0 rounded-lg h-100">
                                        <Card.Body className="d-flex flex-column">

                                            {/* Status de Disponibilidade */}
                                            <div className="mb-3">
                                                <div>
                                                    <strong>Status:</strong>{" "}
                                                </div>
                                                <div className="text-muted ms-2">
                                                    {event.associatedwithuserId ? (
                                                        `Marcado` // Exibe quem criou o agendamento
                                                    ) : (
                                                        "Disponível" // Se não estiver associado a nenhum cliente, está disponível
                                                    )}
                                                </div>
                                            </div>

                                            {/* Cliente Associado */}
                                            {event.associatedwithuserId !== null && (
                                                <div className="mb-3">
                                                    <div>
                                                        <strong>Cliente:</strong>{" "}
                                                    </div>
                                                    <div className="text-muted ms-2">
                                                        {event.createdByUsername} {/* Nome do cliente associado */}
                                                    </div>
                                                </div>
                                            )}

                                            {/* Data e Horários */}
                                            <div>
                                                <div className="text-muted fw-bold" style={{ fontSize: "0.9rem" }}>
                                                    {new Date(event.availabilityStartDate).toLocaleDateString()}
                                                </div>
                                                <div className="text-muted fw-bold" style={{ fontSize: "0.8rem" }}>
                                                    {new Date(event.availabilityStartDate).toLocaleTimeString()} -{" "}
                                                    {new Date(event.availabilityEndDate).toLocaleTimeString()}
                                                </div>
                                            </div>

                                            {/* Ícones */}
                                            <div className="d-flex mt-auto justify-content-end">
                                                {event.associatedwithuserId && (
                                                    <FaInfoCircle
                                                        className="mx-1"
                                                        style={{
                                                            cursor: "pointer",
                                                            color: "#007bff", // Cor original
                                                        }}
                                                        title="Detalhes"
                                                        size={24}
                                                        onClick={() => navigate(`/Schedule/${event.scheduleId}`)} // Navega se estiver marcado
                                                    />
                                                )}
                                                {event.associatedwithuserId && (
                                                    <FaStickyNote
                                                        className="mx-1"
                                                        style={{
                                                            cursor: "pointer",
                                                            color: "rgb(108, 117, 125)", // Cor original
                                                        }}
                                                        title="Notas"
                                                        size={24}
                                                        onClick={() => handleShowModal(event.scheduleId)}
                                                    />
                                                )}
                                                {event.hasAnalgesicPlan !=0 && event.associatedwithuserId && (
                                                   <FileDownloadButton eventId={event.scheduleId} />
                                                )}
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Card>
                            <Card.Body className="d-flex justify-content-between align-items-center">
                                Sem eventos disponíveis.
                            </Card.Body>
                        </Card>
                    )}
                </ListGroup>
            </Row>


            {/* Paginação */}
            <Pagination className="mt-5 justify-content-center">
                {[...Array(totalPages)].map((_, index) => (
                    <Pagination.Item
                        key={index}
                        active={index + 1 === currentPage}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </Pagination.Item>
                ))}
            </Pagination>

            <Modal
                show={showModal}
                onHide={handleCloseModal}
                centered
                className="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Notas do Evento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedScheduleId ? (
                        <ScheduleNotes scheduleId={selectedScheduleId} />
                    ) : (
                        <Spinner animation="border" />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Marcacoes;
