// src/components/ChangePassword.js
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import path from '../../config';

function ChangePassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const navigate = useNavigate();

  const validatePassword = (password) => {
    // Password criteria: at least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    
    // Validate password and set state
    setIsPasswordValid(validatePassword(newPassword));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (!isPasswordValid) {
      setError('Password does not meet complexity requirements');
      return;
    }

    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(path + 'api/changePassword', { // Update URL to your backend endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}` // Include token for authentication
        },
        body: JSON.stringify({ password }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log('Password changed successfully');
        localStorage.removeItem('userToken'); // Invalidate the session by removing the token
        navigate('/login'); // Redirect to the login page after successful password change
      } else {
        setError(data.message || 'Password change failed');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setError('An error occurred: ' + error.message);
    }
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="p-4 shadow-lg border-0 rounded-3">
            <Card.Body>
              <h2 className="mb-4 fw-bold textVet">Alterar palavra-passe</h2>
              {error && <p className="text-danger text-center">{error}</p>} {/* Display error message */}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicPassword" className="mb-3">
                  <Form.Label>Nova palavra-passe</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Digitar nova palavra-chave"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                    className="rounded px-3 py-2"
                  />
                  {!isPasswordValid && password && (
                    <p className="text-danger">
                      Palavra-passe deve conter pelo menos 8 caracteres, conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caracter especial.
                    </p>
                  )}
                </Form.Group>

                <Form.Group controlId="formConfirmPassword" className="mb-3">
                  <Form.Label>Confirmar palavra-passe</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirmar palavra-chave"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    className="rounded px-3 py-2"
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Alterar palavra-passe
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ChangePassword;
