import React, { useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import {Row, Col, ListGroup, Spinner, Card, Button, Modal,} from "react-bootstrap";
import { FaInfoCircle, FaStickyNote, FaClipboard } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import path from "../config";
import ScheduleNotes from "../Scheduler/ViewNotes"; // Import the ScheduleNotes component
import FileDownloadButton from "../Files/FileDownloadButton";
import Swal from "sweetalert2"; // Import SweetAlert2

function AnestethistDashboard() {
  const [NextEvents, setNextEvents] = useState([]); // Track selected schedule
  const [LastEvents, setLastEvents] = useState([]); // Track selected schedule
  const [CurrentEvents, setCurrentEvents] = useState([]); // Track selected schedule
  const [username, setUsername] = useState("User"); // Placeholder for logged-in user's name
  const [loading, setLoading] = useState(true); // Loading state
  const [showModal, setShowModal] = useState(false);
  const [selectedScheduleId, setSelectedScheduleId] = useState(null); // New state for scheduleId

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfileStatus = async () => {
      try {
        const token = localStorage.getItem("userToken");
        if (!token) {
          throw new Error("Token não encontrado");
        }
    
        // Decodificando o token
        const decodedToken = jwtDecode(token); 
    
        // Verificando o valor de profileCompleted no token
        if (decodedToken.profileCompleted === false) {
          // Fazendo a requisição apenas se profileCompleted for false
          const response = await fetch(path + "user/getusercompleted", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
    
          if (!response.ok) {
            throw new Error("Falha ao verificar status do perfil");
          }
    
          const data = await response.json();
    
          // Verificando novamente o status do perfil, caso precise de garantir a consistência
          if (data.profileCompleted === false) {
            Swal.fire({
              title: "Conclua o seu perfil",
              text: "Conclua o seu perfil para ter total acesso à plataforma",
              icon: "warning",
              confirmButtonText: "Ok",
            }).then(() => {
              navigate("/MyProfile");
            });
          }
        } else {
          // Se o profileCompleted for true, você pode continuar sem fazer a chamada
          console.log("Perfil já completo.");
        }
      } catch (error) {
        console.error("Erro ao verificar o status do perfil:", error);
      }
    };
  
    const fetchUsername = async () => {
      try {
        const response = await fetch(path + "user/GetUserName", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch username");
        }

        const data = await response.json();
        setUsername(data.username);
      } catch (error) {
        console.error(error);
        setUsername("Utilizador");
      } finally {
        setLoading(false);
      }
    };

    const fetchLastEvents = async () => {
      try {
        const response = await fetch(path + "sche/GetAnesthetistPastEvents", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch last events");
        }

        const data = await response.json();
        setLastEvents(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchNextEvents = async () => {
      try {
        const response = await fetch(path + "sche/GetAnesthetistNextEvents", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch last events");
        }

        const data = await response.json();
        setNextEvents(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchcurrentEvents = async () => {
      try {
        const response = await fetch(
          path + "sche/GetAnesthetistCurrentEvents",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch last events");
        }

        const data = await response.json();
        setCurrentEvents(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsername();
    fetchLastEvents();
    fetchNextEvents();
    fetchcurrentEvents();
    fetchUserProfileStatus();
  }, []);

  const handleShowModal = (scheduleId) => {
    setSelectedScheduleId(scheduleId); // Set the selected schedule ID
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedScheduleId(null);
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <div className="container">
      <h2 style={{ marginBottom: "40px" }} className="fw-bold textVet">
        Bem vindo, {username}!
      </h2>
      <Row>
        <Col md={4} lg={4} className="margem">
          <h3 className="event-header">Próximos Eventos</h3>
          <ListGroup>
            {NextEvents.length > 0 ? (
              <Row className="g-3">
                {NextEvents.map((event) => (
                  <Col sm={6} md={12} lg={6}>
                    <Card
                      key={event.id}
                      className="shadow-sm border-0 rounded-lg h-100"
                    >
                      <Card.Body className="d-flex flex-column">
                        {/* Cliente Associado */}
                        {event.associatedwithuserId !== null && (
                          <div className="mb-3">
                            <div>
                              <strong>Cliente:</strong>{" "}
                            </div>
                            <div className="text-muted ms-2">
                              {event.creator.username}
                            </div>
                          </div>
                        )}

                        {/* Data e Horários */}
                        <div>
                          <div
                            className="text-muted fw-bold"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {new Date(event.startDate).toLocaleDateString()}
                          </div>
                          <div
                            className="text-muted fw-bold"
                            style={{ fontSize: "0.8rem" }}
                          >
                            {new Date(event.startDate).toLocaleTimeString()} -{" "}
                            {new Date(event.endDate).toLocaleTimeString()}
                          </div>
                        </div>

                        <div className="d-flex mt-auto justify-content-end">
                          <FaInfoCircle
                            className="mx-1 text-primary"
                            style={{ cursor: "pointer" }}
                            title="Detalhes"
                            size={24}
                            onClick={() => navigate(`/Schedule/${event.id}`)}
                          />
                          <FaStickyNote
                            className="mx-1 text-secondary"
                            style={{ cursor: "pointer" }}
                            title="Notas"
                            size={24}
                            onClick={() => handleShowModal(event.id)}
                          />
                          {event.hasAnalgesicPlan && (
                             <FileDownloadButton eventId={event.id} />
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : (
              <Card>
                <Card.Body className="d-flex justify-content-between align-items-center">
                  Sem eventos disponiveis.
                </Card.Body>
              </Card>
            )}
          </ListGroup>
        </Col>

        <Col md={4} lg={4} className="margem">
          <h3 className="event-header">Eventos a decorrer</h3>
          <ListGroup>
            {CurrentEvents.length > 0 ? (
              <Row className="g-3">
                {CurrentEvents.map((event) => (
                  <Col sm={6} md={12} lg={6}>
                    <Card
                      key={event.id}
                      className="shadow-sm border-0 rounded-lg h-100"
                    >
                      <Card.Body className="d-flex flex-column">
                        {/* Cliente Associado */}
                        {event.associatedwithuserId !== null && (
                          <div className="mb-3">
                            <div>
                              <strong>Cliente:</strong>{" "}
                            </div>
                            <div className="text-muted ms-2">
                              {event.creator.username}
                            </div>
                          </div>
                        )}

                        {/* Data e Horários */}
                        <div>
                          <div
                            className="text-muted fw-bold"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {new Date(event.startDate).toLocaleDateString()}
                          </div>
                          <div
                            className="text-muted fw-bold"
                            style={{ fontSize: "0.8rem" }}
                          >
                            {new Date(event.startDate).toLocaleTimeString()} -{" "}
                            {new Date(event.endDate).toLocaleTimeString()}
                          </div>
                        </div>

                        <div className="d-flex mt-auto justify-content-end">
                          <FaInfoCircle
                            className="mx-1 text-primary"
                            style={{ cursor: "pointer" }}
                            title="Detalhes"
                            size={24}
                            onClick={() => navigate(`/Schedule/${event.id}`)}
                          />
                          <FaStickyNote
                            className="mx-1 text-secondary"
                            style={{ cursor: "pointer" }}
                            title="Notas"
                            size={24}
                            onClick={() => handleShowModal(event.id)}
                          />
                          {event.hasAnalgesicPlan && (
                            <FileDownloadButton eventId={event.id} />
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : (
              <Card>
                <Card.Body className="d-flex justify-content-between align-items-center ">
                  Sem eventos a decorrer.
                </Card.Body>
              </Card>
            )}
          </ListGroup>
        </Col>

        <Col md={4} lg={4} className="margem">
          <h3 className="event-header">Últimos eventos</h3>
          <ListGroup>
            {LastEvents.length > 0 ? (
              <Row className="g-3">
                {LastEvents.map((event) => (
                  <Col sm={6} md={12} lg={6}>
                    <Card
                      key={event.id}
                      className="shadow-sm border-0 rounded-lg h-100"
                    >
                      <Card.Body className="d-flex flex-column">
                        {/* Cliente Associado */}
                        {event.associatedwithuserId !== null && (
                          <div className="mb-3">
                            <div>
                              <strong>Cliente:</strong>{" "}
                            </div>
                            <div className="text-muted ms-2">
                              {event.creator.username}
                            </div>
                          </div>
                        )}

                        {/* Data e Horários */}
                        <div>
                          <div
                            className="text-muted fw-bold"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {new Date(event.startDate).toLocaleDateString()}
                          </div>
                          <div
                            className="text-muted fw-bold"
                            style={{ fontSize: "0.8rem" }}
                          >
                            {new Date(event.startDate).toLocaleTimeString()} -{" "}
                            {new Date(event.endDate).toLocaleTimeString()}
                          </div>
                        </div>

                        <div className="d-flex mt-auto justify-content-end">
                          <FaInfoCircle
                            className="mx-1 text-primary"
                            style={{ cursor: "pointer" }}
                            title="Detalhes"
                            size={24}
                            onClick={() => navigate(`/Schedule/${event.id}`)}
                          />
                          <FaStickyNote
                            className="mx-1 text-secondary"
                            style={{ cursor: "pointer" }}
                            title="Notas"
                            size={24}
                            onClick={() => handleShowModal(event.id)}
                          />
                          {event.hasAnalgesicPlan && (
                            <FileDownloadButton eventId={event.id} />
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : (
              <Card>
                <Card.Body className="d-flex justify-content-between align-items-center">
                  Sem eventos disponiveis.
                </Card.Body>
              </Card>
            )}
          </ListGroup>
        </Col>
      </Row>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        className="modal-xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Notas do Evento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedScheduleId ? (
            <ScheduleNotes scheduleId={selectedScheduleId} />
          ) : (
            <Spinner animation="border" />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AnestethistDashboard;
