import React, { useEffect, useState } from "react";
import { Row, Col, ListGroup, Spinner, Card, Button, Modal, Pagination} from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Importing the calendar styles
import path from "../config";

function SchedulePage() {
  const [date, setDate] = useState(new Date());
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]); // State to hold users
  const [loadingUsers, setLoadingUsers] = useState(false); // Loading state for users
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [selectedSchedule, setSelectedSchedule] = useState(null); // Track selected schedule
  const [selectedScheduleToRemove, setSelectedScheduleToremove] =
    useState(null); // Track selected schedule
  const [selectedUser, setSelectedUser] = useState(null); // Track selected user

  // Function to format date as YYYY-MM-DD for the API
  const formatDateForApi = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Pagination state
  const itemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch schedules when the selected date changes
  const fetchSchedules = async () => {
    setLoading(true);
    try {
      const formattedDate = formatDateForApi(date);
      const response = await fetch(path +
        `sche/GetSchedulesByDay/${formattedDate}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch schedules");

      const data = await response.json();
      setSchedules(data);
    } catch (error) {
      console.error("Error fetching schedules:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, [date]);

  // Pagination logic
  const paginatedSchedules = schedules.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < schedules.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Fetch users with availability for the selected date
  // Fetch users with availability for the selected schedule's start and end dates
  useEffect(() => {
    const fetchUsersWithAvailability = async () => {
      if (!selectedSchedule) return; // Exit if no schedule is selected

      setLoadingUsers(true);
      try {
        const startDate = new Date(selectedSchedule.startDate);
        const endDate = new Date(selectedSchedule.endDate);

        const response = await fetch(path +
          `avail/GetUsersWithAvailability?startDate=${startDate}&endDate=${endDate}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          }
        );

        if (!response.ok)
          throw new Error("Failed to fetch users with availability");

        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error("Error fetching users with availability:", error);
      } finally {
        setLoadingUsers(false);
      }
    };

    fetchUsersWithAvailability();
  }, [selectedSchedule]); // Trigger when selectedSchedule changes

  const handleShowModal = (schedule) => {
    setSelectedSchedule(schedule); // Set the selected schedule
    setShowModal(true); // Show the modal
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user); // Set the selected user
  };

  // Function to associate anesthetist to schedule
  const assignAnesthetistToSchedule = async () => {
    if (!selectedSchedule || !selectedUser) return;

    try {
      const response = await fetch(path +
        `sche/AssignAnesthetist`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
          body: JSON.stringify({
            scheduleId: selectedSchedule.id,
            userId: selectedUser.creator.id,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to assign anesthetist");

      console.log("Anesthetist assigned successfully!");
      fetchSchedules(); // Re-fetch schedules to update the page
      setSelectedUser(null);
      setSelectedSchedule(null);
      setShowModal(false); // Close modal on success
      // Optionally, you can refresh the schedules or display a success message
    } catch (error) {
      console.error("Error assigning anesthetist:", error);
    }
  };

  // Function to deassign anesthetist from schedule
  const deassignAnesthetistToSchedule = async () => {
    if (!selectedScheduleToRemove) return;

    try {
      const response = await fetch(path +
        `sche/DeassignAnesthetist`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
          body: JSON.stringify({
            scheduleId: selectedScheduleToRemove.id,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to deassign anesthetist");

      console.log("Anesthetist deassigned successfully!");
      fetchSchedules(); // Re-fetch schedules to update the page
      setSelectedScheduleToremove(null);
    } catch (error) {
      console.error("Error deassigning anesthetist:", error);
    }
  };

  const handleDeassignClick = (schedule) => {
    setSelectedScheduleToremove(schedule); // Set the schedule to be deassigned
  };

  // Trigger deassignment when selectedScheduleToRemove changes
  useEffect(() => {
    if (selectedScheduleToRemove) {
      deassignAnesthetistToSchedule();
    }
  }, [selectedScheduleToRemove]);

  const handleConfirmSelection = () => {
    if (selectedUser) {
      console.log("Confirmed Selection:", selectedUser);
      assignAnesthetistToSchedule(); // Call the API to assign anesthetist to the selected schedule
    }
  };

  const handleCloseModal = () => {
    setShowModal(false); // Hide the modal
    setSelectedUser(null); // Clear the selected user when modal is closed
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(schedules.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }


  return (
    <div className="container my-5">
      <Row>
        <Col sm={12} md={12} lg={5} xl={4} className="d-flex justify-content-center align-items-center vh-40">
          <div className="d-flex align-items-center justify-content-center text-center">
            <Calendar onChange={setDate} value={date} />
          </div>
        </Col>
        <Col sm={12} md={12} lg={7} xl={8}>
          <h3 className="fw-bold textVet" style={{ marginTop: "40px" }}>
            Marcações para: {date.toLocaleDateString("pt-PT")}
          </h3>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <ListGroup>
              {schedules.length > 0 ? (
                <>
                  <Row className="g-3">
                    {paginatedSchedules.map((schedule) => (
                      <Col sm={6} md={4} lg={6} xl={4} key={schedule.id}>
                        <Card className="shadow-sm border-0 rounded-lg h-100">
                          <Card.Body className="d-flex flex-column">
                            {/* Data e Horários */}
                            <div className="mb-2">
                              <div className="fw-bold" style={{ fontSize: "1rem" }}>
                                {new Date(schedule.startDate).toLocaleTimeString()} -{" "}
                                {new Date(schedule.endDate).toLocaleTimeString()}
                              </div>
                            </div>

                            {/* Cliente */}
                            <div className="mb-2">
                              <div>
                                <strong>Cliente:</strong> {schedule.creator.username}
                              </div>
                              <div className="text-muted">{schedule.creator.email}</div>
                            </div>

                            {/* Anestesista */}
                            {schedule.associatedwithuserId !== null && (
                              <div className="mb-2">
                                <div>
                                  <strong>Anestesista:</strong> {schedule.associatedUser.username}
                                </div>
                                <div className="text-muted">{schedule.associatedUser.email}</div>
                              </div>
                            )}

                            {/* Botões no canto inferior direito */}
                            <div className="mt-auto text-end">
                              {schedule.associatedwithuserId == null ? (
                                <Button
                                  variant="success"
                                  className="me-2"
                                  onClick={() => handleShowModal(schedule)}
                                >
                                  Associar Anestesista
                                </Button>
                              ) : (
                                <Button
                                  variant="outline-danger"
                                  onClick={() => handleDeassignClick(schedule)}
                                >
                                  Desassociar Anestesista
                                </Button>
                              )}
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>

                  <div className="d-flex justify-content-center mt-5">
                    <Pagination>
                      {pageNumbers.map((page) => (
                        <Pagination.Item
                          key={page}
                          active={page === currentPage}
                          onClick={() => setCurrentPage(page)}
                        >
                          {page}
                        </Pagination.Item>
                      ))}
                    </Pagination>
                  </div>
                </>
              ) : (
                <ListGroup.Item className="text-muted shadow-sm border-0 rounded-lg">
                  Sem marcações registadas
                </ListGroup.Item>
              )}
            </ListGroup>
          )}
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Selecionar um anestesista</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingUsers ? (
            <Spinner animation="border" />
          ) : (
            <ListGroup>
              {users.map((user) => (
                <ListGroup.Item
                  key={user.id}
                  onClick={() => handleSelectUser(user)}
                  className={`selectable-item ${selectedUser?.id === user.id ? "selected-item" : ""
                    }`}
                >
                  {user.creator.username} - {user.creator.email}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleConfirmSelection} disabled={!selectedUser}>
            Confirmar Seleção
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}

export default SchedulePage;
