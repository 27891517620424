// src/components/Home.js
import React from 'react';
import { Carousel, Container, Row, Col, Card } from 'react-bootstrap';
import { FaCalendarAlt, FaClipboardCheck, FaHeartbeat } from 'react-icons/fa'; // Importando ícones
import { useTranslation } from 'react-i18next';
import "../CSS/Home.css";

function Home() {
  <meta name="viewport" content="width=device-width, initial-scale=1"></meta>

  const { t } = useTranslation();
  return (
    <>
      {/* Welcome Text */}
      {/*<div className="hero-image">*/}
      <div className="position-relative">
        <img
          src="./assets/imagem_heroi.jpg"
          alt="Imagem heroi"
          className="img-fluid imagem-heroi"
        />
        <div className="hero-text text-center">
          <h1 className="fw-bold textVet">
            {t('welcome_message').split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </h1>
          <h5>{t('welcome_message2')}</h5>
        </div>
      </div>
      {/*</div >*/}


      <Container className="my-5">
        {/* Primeira Seção */}
        <Row className="align-items-center mb-4">
          <Col xs={12} md={6} className="text-start order-1 order-md-1">
            <h2 className="fw-bold textVet">{t('Carousel1Slogan')}</h2>
            <p>{t('Carousel1Text')}</p>
          </Col>
          <Col xs={12} md={6} className="order-2 order-md-2">
            <img
              src="./assets/home1.jpg"
              alt="Primeira imagem"
              className="img-fluid rounded home-img"
              style={{ height: "auto", maxHeight: "400px", width: "100%" }}
            />
          </Col>
        </Row>

        {/* Espaçamento entre as seções */}
        <div className="my-5"></div>

        {/* Segunda Seção */}
        <Row className="align-items-center">
          <Col xs={12} md={6} className="order-2 order-md-1">
            <img
              src="./assets/home2.jpg"
              alt="Segunda imagem"
              className="img-fluid rounded home-img"
              style={{ height: "auto", maxHeight: "400px", width: "100%" }}
            />
          </Col>
          <Col xs={12} md={6} className="text-start order-1 order-md-2">
            <h2 className="fw-bold textVet">{t('Carousel2Slogan')}</h2>
            <p>{t('Carousel2Text')}</p>
          </Col>
        </Row>
      </Container>



      <Container className="my-4">
        <div className="text-center mb-4">
          <div className="separator-container">
            <span className="separator-text"><h5>Descubra como podemos ajudar a otimizar os seus processos anestésicos e melhorar os cuidados com os seus pacientes.</h5></span>
          </div>
        </div>

        <h2 className="text-center mb-4 fw-bold textVet">Como Funciona a Teleanestesia?</h2>
        <Container className="my-4">
          <Row className="g-4"> {/* Adiciona espaço entre os cards */}
            <Col xs={12} sm={6} md={4}>
              <Card className="cardheight shadow-sm border-light">
                <Card.Body className="text-center">
                  <div className="mb-3">
                    <FaCalendarAlt size={40} color="#12747D" />
                  </div>
                  <Card.Title>Agendamento</Card.Title>
                  <Card.Text>
                    Entre em contato conosco para agendar uma sessão de teleanestesia para um procedimento específico.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card className="cardheight shadow-sm border-light">
                <Card.Body className="text-center">
                  <div className="mb-3">
                    <FaClipboardCheck size={40} color="#12747D" />
                  </div>
                  <Card.Title>Preparação</Card.Title>
                  <Card.Text>
                    Antes do procedimento, enviamos os detalhes do plano anestésico personalizado, baseado no animal e no procedimento cirúrgico.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card className="cardheight shadow-sm border-light">
                <Card.Body className="text-center">
                  <div className="mb-3">
                    <FaHeartbeat size={40} color="#12747D" />
                  </div>
                  <Card.Title>Acompanhamento em Tempo Real</Card.Title>
                  <Card.Text>
                    Durante o procedimento, a nossa equipa monitorizará o paciente remotamente, orientando a sua equipa em tempo real para garantir que o processo anestésico ocorra de forma segura e tranquila.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* About Us Section */}
      <section className="black-green-section">
        <Container className="text-center text-white py-5">
          <h2 className="text-center mb-4 fw-bold textVet">DS VetAnesthesia</h2>
          <p className="lead mb-5">
            Excelência em Teleanestesia e Consultoria Veterinária
          </p>
          <Row className="card-row">
            <Col sm={12} lg={3} md={6} className="mb-3">
              <div className="icon-container card-container">
                <i className="fas fa-paw fa-3x icon-style"></i>
                <h5>Vasta experiência em anestesia veterinária</h5>
                <p>
                  Nasceu com o propósito de que mais animais possam ser melhor anestesiados.
                </p>
              </div>
            </Col>
            <Col sm={12} lg={3} md={6} className="mb-3">
              <div className="icon-container card-container">
                <i className="fas fa-user-md fa-3x icon-style"></i>
                <h5>Médicos Veterinários Especializados</h5>
                <p>
                  Somos compostos por médicos veterinários anestesistas dedicados e apaixonados pelo bem-estar e segurança animal.
                </p>
              </div>
            </Col>
            <Col sm={12} lg={3} md={6} className="mb-3">
              <div className="icon-container card-container">
                <i className="fas fa-laptop-medical fa-3x icon-style"></i>
                <h5>Teleanestesia</h5>
                <p>
                  Proporcionamos um plano anestésico personalizado e acompanhamento remoto em tempo real.
                </p>
              </div>
            </Col>
            <Col sm={12} lg={3} md={6} className="mb-3">
              <div className="icon-container card-container">
                <i className="fas fa-book-medical fa-3x icon-style"></i>
                <h5>Consultoria e Formação</h5>
                <p>
                  Oferecemos serviços de consultoria na área de anestesia e analgesia, bem como formação para profissionais da área.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>



    </>
  );
}

export default Home;
