import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Spinner, Tabs, Tab } from "react-bootstrap";
import moment from "moment-timezone";
import { countries } from "country-data";
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import UserProfilePhoto from "../Photos/profilephotoBig"; // Import the UserProfilePhoto component
import "../CSS/MyProfile.css"
import path from "../config";

function UserProfile({ setProfileCompleted } ) {
  const [formData, setFormData] = useState({
    name: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    country: "",
    city: "",
    district: "",
    postalCode: "",
    timezone: "",
    nif: "",
    iban: "",
    utcOffset: 0,
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [timezones, setTimezones] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [loadingConditions, setLoadingConditions] = useState(true);

  const [profileImage, setProfileImage] = useState(null);
  const [photoLoading, setPhotoLoading] = useState(false);
  const [photoSubmitted, setPhotoSubmitted] = useState(false);
  const [photoError, setPhotoError] = useState("");
  const [refreshPhoto, setRefreshPhoto] = useState(false);

  const API_KEY = "BQ7M9XZQ8ZF0";

  // Decode token to get user role
  const token = localStorage.getItem('userToken');
  const userRole = token ? jwtDecode(token).role : null;


  // Função para buscar o fuso horário baseado no país
  const getTimezoneFromCountry = async (countryName) => {
    try {
      // Encontra o país na lista de dados
      const country = countries.all.find(
        (c) => c.name === countryName || c.altNames?.includes(countryName)
      );

      if (!country) {
        console.error("País não encontrado no banco de dados.");
        return;
      }

      const isoCode = country.alpha2; // Código ISO 3166-1 alpha-2
      const response = await axios.get(
        `https://api.timezonedb.com/v2.1/list-time-zone?key=${API_KEY}&format=json&country=${isoCode}`
      );

      if (response.data.status === "OK" && response.data.zones.length > 0) {
        const zones = response.data.zones.map((zone) => zone.zoneName); // Lista de zonas
        setFormData((prevData) => ({
          ...prevData,
          //timezone: zones[0], // Seleciona o primeiro fuso horário por padrão
        }));
        setTimezones(zones); // Armazena a lista de fusos horários no estado
      } else {
        console.error(
          "Nenhum fuso horário encontrado para o país selecionado."
        );
      }
    } catch (error) {
      console.error("Erro ao buscar fusos horários:", error);
    }
  };


  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem("userToken");
      try {
        const response = await fetch(path + "user/getuserprofile", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

        if (!response.ok) {
          throw new Error("Failed to fetch user profile.");
        }

        const data = await response.json();
        if (data && data.timezone) {
          setFormData((prevData) => ({
            ...data,
            timezone: data.timezone || "",
          }));
        }
   
        if (data) {
          // Verifique se 'data' existe e se contém a propriedade 'country'
          if (data.country) {
            const { country } = data;
            getTimezoneFromCountry(country);
          }
        }
      } catch (err) {
        console.error(err);
        setError("Failed to load user profile.");
      }
    };

    const fetchUserConditions = async () => {
      // Fetch user conditions logic
      const token = localStorage.getItem("userToken");
      try {
        const response = await fetch( path + "agreement/GetUserConditions",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

        if (!response.ok) {
          throw new Error("Failed to fetch user conditions.");
        }

        const data = await response.json();
        setConditions(data || []); // Set to empty array if data is null or undefined
      } catch (err) {
        console.error(err);
        setError("Failed to load user conditions.");
      } finally {
        setLoadingConditions(false);
      }
    };

    fetchUserProfile();
    fetchUserConditions();
  }, []);

  const handleImageChange = (event) => {
    if (event.target && event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setProfileImage(file);
    } else {
      console.error("No file selected or invalid event.");
    }
  };

  const calculateUtcOffset = (timezone) => {
    const date = new Date();
    const utcDate = new Date(
      date.toLocaleString("en-US", { timeZone: timezone })
    );
    const offset = Math.floor(
      (utcDate.getTime() - date.getTime()) / (1000 * 60 * 60)
    );
    return offset;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "timezone") {
      const utcOffset = calculateUtcOffset(value);
      setFormData((prevData) => ({ ...prevData, [name]: value, utcOffset }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleCountryChange = (country) => {
    setFormData((prevData) => ({
      ...prevData,
      country: country,
    }));

    // Procura o fuso horário do país selecionado
    getTimezoneFromCountry(country);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("userToken");

    try {
      const response = await fetch(path + "user/updateprofile", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Erro ao atualizar perfil.");
      }

      setSubmitted(true);
      setError("");
    } catch (err) {
      console.error(err);
      setError("Erro ao atualizar perfil. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const handlePhotoSubmit = async () => {
    if (!profileImage) {
      setPhotoError("Please select a photo before uploading.");
      return;
    }

    setPhotoLoading(true);
    const token = localStorage.getItem("userToken");

    try {
      const formData = new FormData();
      formData.append("profilePhoto", profileImage);

      const response = await fetch(path + "photo/uploadphoto", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Error uploading profile photo");
      }

      if (response.ok) {
        // Atualize o estado para forçar a atualização do componente de foto
        setRefreshPhoto((prev) => !prev);
      }
      const data = await response.json(); // Supondo que o servidor retorne a URL da imagem
      setPhotoSubmitted(true);
      setPhotoError("");
      setProfileImage(null); // Limpa a imagem carregada
    } catch (err) {
      console.error(err);
      setPhotoError("Failed to upload photo, please try again.");
    } finally {
      setPhotoLoading(false);
    }
  };

  return (
    <Container>
      <h2 className="text-center mb-4 fw-bold textVet">Atualizar dados do perfil</h2>
      <Row className="justify-content-center">
        <Col md={12}>
          <Tabs
            defaultActiveKey="profile"
            id="user-profile-tabs"
            className="mb-3"
          >
            <Tab eventKey="profile" title="Perfil">
              <Form onSubmit={handleSubmit} className="modern-form">
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-4" controlId="formName">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Introduzir nome"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formAddressLine1">
                      <Form.Label>Morada Linha 1</Form.Label>
                      <Form.Control
                        type="text"
                        name="addressLine1"
                        placeholder="Introduza morada"
                        value={formData.addressLine1}
                        onChange={handleChange}
                        required
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formCountry">
                      <Form.Label>País</Form.Label>
                      <CountryDropdown
                        value={formData.country}
                        onChange={(val) => {
                          // Atualiza o país e reinicia a cidade
                          setFormData((prevData) => ({
                            ...prevData,
                            country: val,
                            district: "", // Reinicia distrito ao mudar país
                          }));

                          // Chama a função para atualizar o fuso horário com base no país
                          handleCountryChange(val);
                        }}
                        defaultOptionLabel="Selecione um país"
                        className="form-control"
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="formdistrict">
                      <Form.Label>Distrito</Form.Label>
                      <RegionDropdown
                        country={formData.country} // Filtro com base no país selecionado
                        value={formData.district}
                        onChange={(val) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            district: val,
                          }))
                        }
                        defaultOptionLabel="Selecione um distrito"
                        className="form-control"
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="formPostalCode">
                      <Form.Label>Código Postal</Form.Label>
                      <Form.Control
                        type="text"
                        name="postalCode"
                        placeholder="Introduza o código postal"
                        value={formData.postalCode}
                        onChange={handleChange}
                        required
                        className="form-input"
                      />
                    </Form.Group>

                    {/* Exibir IBAN somente se a role for 'Anesthetist' */}
                    {userRole === 'Anesthetist' && (
                      <Form.Group className="mb-4" controlId="formIBAN">
                        <Form.Label>IBAN</Form.Label>
                        <Form.Control
                          type="text"
                          name="iban"
                          placeholder="Introduza o seu IBAN"
                          value={formData.iban}
                          onChange={handleChange}
                          required
                          className="form-input"
                        />
                      </Form.Group>
                    )}

                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-4" controlId="formAddressLine2">
                      <Form.Label>Morada Linha 2 (opcional)</Form.Label>
                      <Form.Control
                        type="text"
                        name="addressLine2"
                        placeholder="Introduza a morada 2"
                        value={formData.addressLine2}
                        onChange={handleChange}
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formAddressLine3">
                      <Form.Label>Morada Linha 3 (opcional)</Form.Label>
                      <Form.Control
                        type="text"
                        name="addressLine3"
                        placeholder="Introduza a sua morada 3"
                        value={formData.addressLine3}
                        onChange={handleChange}
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formStateOrProvince"
                    >
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        placeholder="Introduza a sua Cidade"
                        value={formData.city}
                        onChange={handleChange}
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formTimezone">
                      <Form.Label>Fuso Horário</Form.Label>
                      <Form.Control
                        as="select"
                        name="timezone"
                        value={formData.timezone}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Selecione um fuso horário</option>
                        {timezones.map((tz) => (
                          <option key={tz} value={tz}>
                            {tz}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formNIF">
                      <Form.Label>NIF</Form.Label>
                      <Form.Control
                        type="text"
                        name="nif"
                        placeholder="Introduza o seu NIF"
                        value={formData.nif}
                        onChange={handleChange}
                        required
                        className="form-input"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  variant="primary"
                  type="submit"
                  className="submit-button w-100"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner as="span" animation="border" size="sm" />
                  ) : (
                    "Atualizar Perfil"
                  )}
                </Button>
              </Form>
              {submitted && (
                <div className="text-success mt-3">
                  Perfil atualizado com sucesso!
                </div>
              )}
              {error && <div className="text-danger mt-3">{error}</div>}
            </Tab>
            <Tab eventKey="photo" title="Foto de Perfil">
              <div className="text-center">
                <UserProfilePhoto refresh={refreshPhoto} />
              </div>

              <Form.Group className="text-center">
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="form-file-input mb-2 mx-auto"
                  style={{ width: 'auto', marginTop: '18px' }}
                />
                <div className="mt-2">
                  <Button
                    variant="primary"
                    onClick={handlePhotoSubmit}
                    disabled={photoLoading}
                  >
                    {photoLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Enviar"
                    )}
                  </Button>
                </div>
                {photoError && <p className="text-danger">{photoError}</p>}
                {photoSubmitted && (
                  <p className="text-success mt-2">
                    Foto de perfil atualizada com sucesso!
                  </p>
                )}
              </Form.Group>
            </Tab>
            <Tab eventKey="conditions" title="Condições">
              {loadingConditions ? (
                <Spinner animation="border" />
              ) : (
                <div>
                  {conditions.length > 0 ? (
                    conditions.map((condition, index) => (
                      <div key={index} className="condition-card mb-3 p-3">
                        <div style={{ textAlign: "right", color: "green" }}>
                          <strong>Data de Aceitação:</strong>{" "}
                          {moment(condition.DateOfAcceptance).format(
                            "DD/MM/YYYY"
                          )}
                        </div>
                        <hr
                          style={{ margin: "10px 0", border: "1px solid #ccc" }}
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: condition.ConditionsAccepted,
                          }}
                        />
                      </div>
                    ))
                  ) : (
                    <div>
                      Nenhuma condição encontrada. Total de condições:{" "}
                      {conditions.length}
                    </div>
                  )}
                </div>
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}

export default UserProfile;
