import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useLocation, useNavigate } from 'react-router-dom';
import { countries } from "country-data";
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import path from "../config"

function RegisterPersonalData() {
  const location = useLocation();
  const navigate = useNavigate();

  // Dados recebidos da primeira página
  const { username, email, password } = location.state || {};
  console.log(username, email, password)

  const [formData, setFormData] = useState({
    username: username || '',
    email: email || '',
    password: password || '',
    name: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    country: '',
    district: '',
    city: '',
    postalCode: '',
    nif: '',
    timezone: '',
    iban: '',
    honeypot: '', // Hidden field for honeypot
  });

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [timezones, setTimezones] = useState([]);

  const API_KEY = "BQ7M9XZQ8ZF0";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    // Check if the honeypot field is empty
    if (formData.honeypot) {
      alert("Por favor, prove que não é um robot.");
      return;
    }

    try {
      const response = await fetch(path + 'api/register-client', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)});

      const data = await response.json();

      if (response.status === 200) {
        console.log('Utilizador registado com sucesso', data);
        setSubmitted(true);
        navigate('/login'); // Redireciona para outra página, se necessário
      } else {
        setError('Ocorreu um erro durante o registo. Tente novamente.', data);
      }
    } catch (err) {
      console.error(err);
      setError('Ocorreu um erro durante o registo. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };


  // Função para buscar o fuso horário baseado no país
  const getTimezoneFromCountry = async (countryName) => {
    try {
      // Encontra o país na lista de dados
      const country = countries.all.find(
        (c) => c.name === countryName || c.altNames?.includes(countryName)
      );

      if (!country) {
        console.error("País não encontrado no banco de dados.");
        return;
      }

      const isoCode = country.alpha2; // Código ISO 3166-1 alpha-2
      const response = await axios.get(
        `https://api.timezonedb.com/v2.1/list-time-zone?key=${API_KEY}&format=json&country=${isoCode}`
      );

      if (response.data.status === "OK" && response.data.zones.length > 0) {
        const zones = response.data.zones.map((zone) => zone.zoneName); // Lista de zonas
        setFormData((prevData) => ({
          ...prevData,
          //timezone: zones[0], // Seleciona o primeiro fuso horário por padrão
        }));
        setTimezones(zones); // Armazena a lista de fusos horários no estado
      } else {
        console.error(
          "Nenhum fuso horário encontrado para o país selecionado."
        );
      }
    } catch (error) {
      console.error("Erro ao buscar fusos horários:", error);
    }
  };

  const handleCountryChange = (country) => {
    setFormData((prevData) => ({
      ...prevData,
      country: country,
    }));

    // Procura o fuso horário do país selecionado
    getTimezoneFromCountry(country);
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="p-4 shadow-lg border-0 rounded-3">
            <Card.Body>
              <h2 className="mb-4 fw-bold textVet">Registar Perfil</h2>
              {error && <div className="text-danger text-center mb-3">{error}</div>}
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-4" controlId="formName">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Introduzir nome"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formAddressLine1">
                      <Form.Label>Morada Linha 1</Form.Label>
                      <Form.Control
                        type="text"
                        name="addressLine1"
                        placeholder="Introduza morada"
                        value={formData.addressLine1}
                        onChange={handleChange}
                        required
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formCountry">
                      <Form.Label>País</Form.Label>
                      <CountryDropdown
                        value={formData.country}
                        onChange={(val) => {
                          // Atualiza o país e reinicia a cidade
                          setFormData((prevData) => ({
                            ...prevData,
                            country: val,
                            district: "", // Reinicia distrito ao mudar país
                          }));

                          // Chama a função para atualizar o fuso horário com base no país
                          handleCountryChange(val);
                        }}
                        defaultOptionLabel="Selecione um país"
                        className="form-control"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formDistrict">
                      <Form.Label>Distrito</Form.Label>
                      <RegionDropdown
                        country={formData.country} // Filtro com base no país selecionado
                        value={formData.district}
                        onChange={(val) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            district: val,
                          }))
                        }
                        defaultOptionLabel="Selecione um distrito"
                        className="form-control"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formPostalCode">
                      <Form.Label>Código Postal</Form.Label>
                      <Form.Control
                        type="text"
                        name="postalCode"
                        placeholder="Introduza o código postal"
                        value={formData.postalCode}
                        onChange={handleChange}
                        required
                        className="form-input"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-4" controlId="formAddressLine2">
                      <Form.Label>Morada Linha 2 (opcional)</Form.Label>
                      <Form.Control
                        type="text"
                        name="addressLine2"
                        placeholder="Introduza a morada 2"
                        value={formData.addressLine2}
                        onChange={handleChange}
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formAddressLine3">
                      <Form.Label>Morada Linha 3 (opcional)</Form.Label>
                      <Form.Control
                        type="text"
                        name="addressLine3"
                        placeholder="Introduza a sua morada 3"
                        value={formData.addressLine3}
                        onChange={handleChange}
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formCity">
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        placeholder="Introduza a sua Cidade"
                        value={formData.city}
                        onChange={handleChange}
                        className="form-input"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formTimezone">
                      <Form.Label>Fuso Horário</Form.Label>
                      <Form.Control
                        as="select"
                        name="timezone"
                        value={formData.timezone}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Selecione um fuso horário</option>
                        {timezones.map((tz) => (
                          <option key={tz} value={tz}>
                            {tz}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formNIF">
                      <Form.Label>NIF</Form.Label>
                      <Form.Control
                        type="text"
                        name="nif"
                        placeholder="Introduza o seu NIF"
                        value={formData.nif}
                        onChange={handleChange}
                        required
                        className="form-input"
                      />
                    </Form.Group>
                    {/* Honeypot field */}
                <Form.Group style={{ display: 'none' }} controlId="formHoneypot">
                  <Form.Control
                    type="text"
                    name="honeypot"
                    placeholder="Leave empty"
                    value={formData.honeypot}
                    onChange={handleChange}
                  />
                </Form.Group>
                  </Col>
                </Row>
                <Button
                  variant="primary"
                  type="submit"
                  className="w-100"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner as="span" animation="border" size="sm" />
                  ) : (
                    'Registar'
                  )}
                </Button>
              </Form>
              {submitted && (
                <div className="text-success mt-3">Perfil registrado com sucesso!</div>
              )}
              {error && <div className="text-danger mt-3">{error}</div>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default RegisterPersonalData;
