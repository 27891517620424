import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import path from "../config";
import { jwtDecode } from "jwt-decode";

const ScheduleQuestionary = ({ scheduleId }) => {
  const [form, setForm] = useState({
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false); // Para controlar se o formulário é apenas visualização
  const [isFormValid, setIsFormValid] = useState(false); // Estado para validar se o formulário está preenchido
  const [userRole, setUserRole] = useState(null);

  const checkUserRole = () => {
    const token = localStorage.getItem("userToken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token); // Decodifica o token
        setUserRole(decodedToken.role); // Armazena a role do usuário
      } catch (error) {
        console.error("Erro ao decodificar o token:", error);
      }
    }
  };

  // Verifica a role do usuário assim que o componente é carregado
  useEffect(() => {
    checkUserRole();
  }, []);
  
  // Busca os dados da avaliação da BD ao carregar o componente
  useEffect(() => {
    const fetchEvaluation = async () => {
      try {
        const response = await axios.get(
          path + `evaluation/getScheduleEvaluation/${scheduleId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          }
        );
        if (response.data) {
          setForm({
            question1: response.data.question1 || "",
            question2: response.data.question2 || "",
            question3: response.data.question3 || "",
            question4: response.data.question4 || "",
            question5: response.data.question5 || "",
          });
          setIsReadOnly(true); // Bloqueia o formulário se já existir avaliação
        }
      } catch (error) {}
    };

    fetchEvaluation();
  }, [scheduleId]);

  // Função para verificar se todos os campos foram preenchidos
  const checkFormValidity = () => {
    // Verifica se todas as perguntas estão respondidas
    const allAnswered = Object.values(form).every((answer) => answer !== "");
    setIsFormValid(allAnswered);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    checkFormValidity(); // Verifica a cada mudança se o formulário está válido
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isReadOnly || !isFormValid) return; // Bloqueia o envio se o formulário for apenas visualização ou não for válido

    try {
      await axios.post(
        `${path}evaluation/setScheduleEvaluation/`,
        {
          ScheduleId: scheduleId,
          ...form,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      alert("Questionário enviado com sucesso!");
      setIsModalOpen(false); // Fecha a modal após o envio
      setIsReadOnly(true);
    } catch (error) {
      console.error("Erro ao enviar questionário:", error);
      alert("Erro ao enviar questionário.");
    }
  };

  return (
    <>
    {/* Verifica se o usuário tem a role necessária */}
    {(userRole === "Admin" || userRole === "Client") && (
      <>
        <div
          style={{ fontSize: "24px", cursor: "pointer", color: "#33648A" }}
          onClick={() => setIsModalOpen(true)}
        >
          <FaStar /> {/* Ícone clicável para abrir a modal */}
        </div>

        {/* Modal */}
        <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Avaliação</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              {/* Pergunta 1 */}
              <div className="mb-3">
                <label htmlFor="question1" className="form-label">
                  1. Como avalia a facilidade de utilização?
                </label>
                <select
                  id="question1"
                  name="question1"
                  value={form.question1}
                  onChange={handleChange}
                  required
                  disabled={isReadOnly}
                  className="form-select"
                >
                  <option value="">Selecione</option>
                  <option value="very_satisfied">Muito satisfeito</option>
                  <option value="satisfied">Satisfeito</option>
                  <option value="dissatisfied">Insatisfeito</option>
                  <option value="very_dissatisfied">Muito insatisfeito</option>
                </select>
              </div>

              {/* Pergunta 2 */}
              <div className="mb-3">
                <label htmlFor="question2" className="form-label">
                  2. A qualidade da ligação foi adequada?
                </label>
                <select
                  id="question2"
                  name="question2"
                  value={form.question2}
                  onChange={handleChange}
                  required
                  disabled={isReadOnly}
                  className="form-select"
                >
                  <option value="">Selecione</option>
                  <option value="very_adequate">Muito adequada</option>
                  <option value="adequate">Adequada</option>
                  <option value="inadequate">Inadequada</option>
                  <option value="very_inadequate">Muito inadequada</option>
                </select>
              </div>

              {/* Pergunta 3 */}
              <div className="mb-3">
                <label htmlFor="question3" className="form-label">
                  3. A qualidade da interação foi satisfatória?
                </label>
                <select
                  id="question3"
                  name="question3"
                  value={form.question3}
                  onChange={handleChange}
                  required
                  disabled={isReadOnly}
                  className="form-select"
                >
                  <option value="">Selecione</option>
                  <option value="yes">Sim</option>
                  <option value="partially">Parcialmente</option>
                  <option value="no">Não</option>
                </select>
              </div>

              {/* Pergunta 4 */}
              <div className="mb-3">
                <label htmlFor="question4" className="form-label">
                  4. Sentiu segurança nos aconselhamentos?
                </label>
                <select
                  id="question4"
                  name="question4"
                  value={form.question4}
                  onChange={handleChange}
                  required
                  disabled={isReadOnly}
                  className="form-select"
                >
                  <option value="">Selecione</option>
                  <option value="yes">Sim</option>
                  <option value="partially">Parcialmente</option>
                  <option value="no">Não</option>
                </select>
              </div>

              {/* Pergunta 5 */}
              <div className="mb-3">
                <label htmlFor="question5" className="form-label">
                  5. Qual a nota para o serviço?
                </label>
                <select
                  id="question5"
                  name="question5"
                  value={form.question5}
                  onChange={handleChange}
                  required
                  disabled={isReadOnly}
                  className="form-select"
                >
                  <option value="">Selecione</option>
                  <option value="5">5</option>
                  <option value="4">4</option>
                  <option value="3">3</option>
                  <option value="2">2</option>
                  <option value="1">1</option>
                  <option value="0">0</option>
                </select>
              </div>

              {/* Botões */}
              <div className="text-end mt-3">
                <Button
                  variant="secondary"
                  onClick={() => setIsModalOpen(false)}
                  className="me-2"
                >
                  Cancelar
                </Button>
                {!isReadOnly && (
                  <Button type="submit" variant="primary" disabled={!isFormValid}>
                    Enviar
                  </Button>
                )}
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    )}
  </>
  );
};

export default ScheduleQuestionary;
