// ScheduleQuestionaryForm.js
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Alert } from "react-bootstrap";

const ScheduleQuestionaryForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    medicalHistory: "",
    species: "",
    breed: "",
    age: 0,
    weight: 0,
    physicalExam: "",
    additionalExams: "",
    plannedProcedure: "",
    chronicMedication: "",
    temperament: "",
    comments: "",
  });
  
  const [showAlert, setShowAlert] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setShowAlert(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verificar se todos os campos obrigatórios estão preenchidos
    if (Object.values(formData).some((value) => value.trim() === "")) {
      setShowAlert(true);
      return;
    }

    // Enviar os dados do formulário
    onSubmit(formData);
  };

  // Notificar o componente pai sempre que formData for alterado
  useEffect(() => {
    onSubmit(formData); // Chama a função de callback com o estado atualizado
  }, [formData, onSubmit]); // Executa sempre que formData mudar


  return (
    <Form onSubmit={handleSubmit}>
       {showAlert && <Alert variant="danger">Preencha todos os campos obrigatórios!</Alert>}

      {/* Row 1: Medical History & Species */}
      <Row>
        <Col xs={12} md={6}>
          <Form.Group controlId="medicalHistory">
            <Form.Label>Histórico Médico</Form.Label>
            <Form.Control
              type="text"
              name="medicalHistory"
              value={formData.medicalHistory}
              onChange={handleChange}
              maxLength={500}
              onInvalid={(e) => e.target.setCustomValidity("Máximo de 500 caracteres!")}
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId="species">
            <Form.Label>Espécie</Form.Label>
            <Form.Control
              as="select"
              name="species"
              value={formData.species}
              onChange={handleChange}
              required
            >
              <option value="">Selecione</option>
              <option value="Dog">Cão</option>
              <option value="Cat">Gato</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      {/* Row 2: Breed & Age */}
      <Row>
        <Col xs={12} md={6}>
          <Form.Group controlId="breed">
            <Form.Label>Raça</Form.Label>
            <Form.Control
              type="text"
              name="breed"
              value={formData.breed}
              onChange={handleChange}
              maxLength={20}
              onInvalid={(e) => e.target.setCustomValidity("Máximo de 20 caracteres!")}
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId="age">
            <Form.Label>Idade (entre 0 e 20 anos)</Form.Label>
            <Form.Control
              type="number"
              name="age"
              value={formData.age}
              onChange={handleChange}
              min="0"
              max="20"
              title="Insira uma idade entre 0 e 20 anos."
              onInvalid={(e) => e.target.setCustomValidity("A idade deve estar entre 0,5 e 20 anos!")}
              onInput={(e) => e.target.setCustomValidity("")}
              required
              onBlur={(e) => {
                let value = parseFloat(e.target.value);
                if (value < 0) {
                  value = 0;
                } else if (value > 20) {
                  value = 20;
                }
                setFormData((prevData) => ({ ...prevData, age: value }));
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Row 3: Weight & Physical Exam */}
      <Row>
        <Col xs={12} md={6}>
          <Form.Group controlId="weight">
            <Form.Label>Peso (Com até 1 casa decimal)</Form.Label>
            <Form.Control
              type="number"
              name="weight"
              value={formData.weight}
              onChange={handleChange}
              pattern="^\d+(\.\d{1})?$"
              onInvalid={(e) =>
                e.target.setCustomValidity("Peso deve ser um número com até 1 casa decimal!")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              required
              onBlur={(e) => {
                let value = parseFloat(e.target.value);
                if (value < 0) {
                  value = 0;
                } else if (value > 100000) {
                  value = 100000;
                } else if (!/^\d+(\.\d{1})?$/.test(value.toString())) {
                  value = value.toFixed(1); // Ensure 1 decimal place if pattern is violated
                }
                setFormData((prevData) => ({ ...prevData, weight: value }));
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId="physicalExam">
            <Form.Label>Exame Físico</Form.Label>
            <Form.Control
              type="text"
              name="physicalExam"
              value={formData.physicalExam}
              onChange={handleChange}
              maxLength={300}
              onInvalid={(e) => e.target.setCustomValidity("Máximo de 300 caracteres!")}
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Row 4: Additional Exams & Planned Procedure */}
      <Row>
        <Col xs={12} md={6}>
          <Form.Group controlId="additionalExams">
            <Form.Label>Exames Adicionais</Form.Label>
            <Form.Control
              type="text"
              name="additionalExams"
              value={formData.additionalExams}
              onChange={handleChange}
              maxLength={200}
              onInvalid={(e) => e.target.setCustomValidity("Máximo de 200 caracteres!")}
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId="plannedProcedure">
            <Form.Label>Procedimento Planeado</Form.Label>
            <Form.Control
              type="text"
              name="plannedProcedure"
              value={formData.plannedProcedure}
              onChange={handleChange}
              maxLength={200}
              onInvalid={(e) => e.target.setCustomValidity("Máximo de 200 caracteres!")}
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Row 5: Chronic Medication & Temperament */}
      <Row>
        <Col xs={12} md={6}>
          <Form.Group controlId="chronicMedication">
            <Form.Label>Medicação Crônica</Form.Label>
            <Form.Control
              type="text"
              name="chronicMedication"
              value={formData.chronicMedication}
              onChange={handleChange}
              maxLength={300}
              onInvalid={(e) => e.target.setCustomValidity("Máximo de 300 caracteres!")}
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId="temperament">
            <Form.Label>Temperamento</Form.Label>
            <Form.Control
              as="select"
              name="temperament"
              value={formData.temperament}
              onChange={handleChange}
              required
            >
              <option value="">Selecione</option>
              <option value="Calm">Calmo</option>
              <option value="Agitated">Agitado</option>
              <option value="Anxious">Ansioso</option>
              <option value="Aggressive">Agressivo</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      {/* Row 6: Comments */}
      <Row>
        <Col xs={12}>
          <Form.Group controlId="comments">
            <Form.Label>Comentários Adicionais</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="comments"
              value={formData.comments}
              onChange={handleChange}
              maxLength={500}
              onInvalid={(e) => e.target.setCustomValidity("Máximo de 500 caracteres!")}
              onInput={(e) => e.target.setCustomValidity("")}
            />
          </Form.Group>
        </Col>
      </Row>

    </Form>
  );
};

export default ScheduleQuestionaryForm;
