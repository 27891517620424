import React, { useEffect, useState } from 'react';
import path from '../config';

const UserProfilePhoto = ({ refresh }) => {
  const [imagePreview, setImagePreview] = useState('');

  useEffect(() => {
    const fetchProfilePhoto = async () => {
      const token = localStorage.getItem("userToken");
      try {
        const photoResponse = await fetch(path + `photo/profilephoto`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });


        if (photoResponse.status === 200) {
          const photoBlob = await photoResponse.blob(); // Get the response as a blob
          const photoUrl = URL.createObjectURL(photoBlob); // Create a local URL for the blob
          setImagePreview(photoUrl);
        } else {
          setImagePreview(''); // Reset if there's an error
        }
      } catch (error) {
        setImagePreview(''); // Reset on error
      }
    };

    fetchProfilePhoto();
  }, [refresh]); // Re-run when `refresh` changes

  return (
    <div>
    {imagePreview ? (
      <img 
      src={imagePreview} 
      alt="Profile" 
      width= {40}
      height= {40} 
      className="rounded-circle me-2" // Add some margin to the right
      />
    ) : (
      <img
        src="/assets/default-profile.webp"
        width={40}
        height={40}
        alt="Profile"
        className="rounded-circle me-2" // Add some margin to the right
      />
    )}
  </div>
  );
};

export default UserProfilePhoto;

