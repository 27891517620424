import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col, ListGroup, Spinner, Card, Button, Modal } from "react-bootstrap";
import { FaInfoCircle, FaStickyNote, FaClipboard, FaPlus } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import path from "../config";
import ScheduleNotes from "../Scheduler/ViewNotes"; // Import the ScheduleNotes component
import FileDownloadButton from "../Files/FileDownloadButton";


function ClientDashboard() {
  const [NextEvents, setNextEvents] = useState([]); // Track selected schedule
  const [LastEvents, setLastEvents] = useState([]); // Track selected schedule
  const [username, setUsername] = useState("User"); // Placeholder for logged-in user's name
  const [loading, setLoading] = useState(true); // Loading state
  const [showModal, setShowModal] = useState(false);
  const [selectedScheduleId, setSelectedScheduleId] = useState(null); // New state for scheduleId
  

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch username from the API
    const fetchUsername = async () => {
      try {
        const response = await fetch(path + "user/GetUserName", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`, // Include the token in the header
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch username");
        }

        const data = await response.json();
        setUsername(data.username); // Adjust based on your API response structure
      } catch (error) {
        console.error(error);
        setUsername("Utilizador"); // Fallback username in case of error
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    // Fetch last events from the API
    const fetchLastEvents = async () => {
      try {
        const response = await fetch(
          path + "sche/GetPastEvents",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`, // Include the token in the header
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch last events");
        }

        const data = await response.json();
        setLastEvents(data); // Set last events from API response
      } catch (error) {
        console.error(error);
      }
    };

    // Fetch last events from the API
    const fetchNextEvents = async () => {
      try {
        const response = await fetch(
          path + "sche/GetNextEvents",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`, // Include the token in the header
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch last events");
        }

        const data = await response.json();
        setNextEvents(data); // Set last events from API response
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsername();
    fetchLastEvents(); // Call to fetch Last events
    fetchNextEvents(); // Call to fetch Next Events
  }, []);

  const handleShowModal = (scheduleId) => {
    setSelectedScheduleId(scheduleId); // Set the selected schedule ID
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedScheduleId(null);
  };


  if (loading) {
    return <Spinner animation="border" />; // Show a loading spinner while fetching
  }

  return (

    <div className="container">
      <h2 style={{ marginBottom: "40px" }} className="fw-bold textVet">Bem vindo, {username}!</h2>
      <Row>
        <Col md={6} lg={6} className="border-end pe-2 margem">
          <h3 style={{ marginBottom: "20px", fontSize: "1.5rem" }}>
            Próximos Eventos
            <Link to="/Client" className="ms-2">
              <FaPlus
                className="mx-2"
                style={{ cursor: "pointer", color: "#12747D" }}
                title="Add Event"
                size={24}
              />
            </Link>
          </h3>

          <ListGroup>
            {NextEvents.length > 0 ? (
              <Row className="g-3">
                {NextEvents.map((event) => (
                  <Col sm={6} md={12} lg={6} key={event.id}>
                    <Card key={event.id} className="shadow-sm border-0 rounded-lg h-100">
                      <Card.Body className="d-flex flex-column">

                        {/* Anestesista Associado */}
                        {event.associatedwithuserId !== null && (
                          <div className="mb-3 ">
                            <div>
                              <strong>Anestesista Associado:</strong>
                            </div>
                            <div className="text-muted ms-2">
                              {event.associatedUser.username}
                            </div>
                          </div>
                        )}

                        {/* Data e Horários */}
                        <div>
                          <div className="text-muted fw-bold" style={{ fontSize: "0.9rem" }}>
                            {new Date(event.startDate).toLocaleDateString()}
                          </div>
                          <div className="text-muted fw-bold" style={{ fontSize: "0.8rem" }}>
                            {new Date(event.startDate).toLocaleTimeString()} -{" "}
                            {new Date(event.endDate).toLocaleTimeString()}
                          </div>
                        </div>


                        <div className="d-flex mt-auto justify-content-end">
                          <FaInfoCircle
                            className="mx-1 text-primary"
                            style={{ cursor: "pointer" }}
                            title="Detalhes"
                            size={24}
                            onClick={() => navigate(`/Schedule/${event.id}`)}
                          />
                          <FaStickyNote
                            className="mx-1 text-secondary"
                            style={{ cursor: "pointer" }}
                            title="Notas"
                            size={24}
                            onClick={() => handleShowModal(event.id)}
                          />
                          {event.hasAnalgesicPlan && (
                            <FileDownloadButton eventId={event.id} />
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : (
              <Card className="shadow-sm border-0 rounded-lg">
                <Card.Body className="d-flex justify-content-center align-items-center text-muted">
                  Sem próximos eventos disponíveis.
                </Card.Body>
              </Card>
            )}
          </ListGroup>

        </Col>
        <Col md={6}>
          <h3 style={{ marginBottom: "20px", fontSize: "1.5rem" }}>
            Últimos eventos
          </h3>
          <ListGroup>
            {LastEvents.length > 0 ? (
              <Row className="g-3">
                {LastEvents.map((event) => (
                  <Col sm={6} md={12} lg={6} key={event.id}>
                    <Card key={event.id} className="shadow-sm border-0 rounded-lg h-100">
                      <Card.Body className="d-flex flex-column">

                        {/* Anestesista Associado */}
                        {event.associatedwithuserId !== null && (
                          <div className="mb-3">
                            <div>
                              <strong>Anestesista Associado:</strong>
                            </div>
                            <div className="text-muted ms-2">
                              {event.associatedUser.username}
                            </div>
                          </div>
                        )}

                        {/* Data e Horários */}
                        <div>
                          <div className="text-muted fw-bold" style={{ fontSize: "0.9rem" }}>
                            {new Date(event.startDate).toLocaleDateString()}
                          </div>
                          <div className="text-muted fw-bold" style={{ fontSize: "0.8rem" }}>
                            {new Date(event.startDate).toLocaleTimeString()} -{" "}
                            {new Date(event.endDate).toLocaleTimeString()}
                          </div>
                        </div>

                        {/* Ícones de ação */}
                        <div className="d-flex mt-auto justify-content-end">
                          <FaInfoCircle
                            className="mx-1 text-primary"
                            style={{ cursor: "pointer" }}
                            title="Detalhes"
                            size={24}
                            onClick={() => navigate(`/Schedule/${event.id}`)}
                          />
                          <FaStickyNote
                            className="mx-1 text-secondary"
                            style={{ cursor: "pointer" }}
                            title="Notas"
                            size={24}
                            onClick={() => handleShowModal(event.id)}
                          />
                          {event.hasAnalgesicPlan && (
                            <FileDownloadButton eventId={event.id} />
                          )}
                        </div>

                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : (
              <Card className="shadow-sm border-0 rounded-lg">
                <Card.Body className="d-flex justify-content-center align-items-center text-muted">
                  Sem últimos eventos disponíveis.
                </Card.Body>
              </Card>
            )}
          </ListGroup>

        </Col>
      </Row>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        className="modal-xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Notas do Evento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedScheduleId ? (
            <ScheduleNotes scheduleId={selectedScheduleId} />
          ) : (
            <Spinner animation="border" />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default ClientDashboard;
