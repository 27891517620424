import React, { useState, useEffect } from "react";
import { Container, Card, Button, Modal } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from "@fullcalendar/core/locales/pt"; // Portuguese locale
import axios from "axios"; // Axios for API requests
import { format } from "date-fns"; // Import the date formatting function
import "./calendar.css";
import path from "../../config";
import ScheduleQuestionaryForm from "./ScheduleQuestionaryForm";
import { toZonedTime, format as formatTZ } from "date-fns-tz";

const Calendar = () => {
  const [schedules, setschedules] = useState([]);
  const [businessHours, setBusinessHours] = useState([]);
  const [selectedRange, setSelectedRange] = useState(null); // For storing selected date range
  const [showModal, setShowModal] = useState(false); // Modal state
  const [showModalDelete, setShowModalDelete] = useState(false); // Modal state
  const [error, setError] = useState("");
  const [currentEvent, setCurrentEvent] = useState(null); // To store the current event being edited
  const [isSchedulesEmpty, setIsSchedulesEmpty] = useState(false); // Verifica se não há eventos
  const [localformdata, setlocalformdata] = useState([]);

  // Função para determinar a visualização com base no tamanho da tela
  const updateViewBasedOnScreenSize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      // Se a largura da tela for menor ou igual a 768px (md ou menor)
      return "timeGridDay"; // Exibe apenas o dia
    } else {
      return "timeGridWeek"; // Exibe a semana
    }
  };

  const updateButtonBasedOnScreenSize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      // Se a largura da tela for menor ou igual a 768px (md ou menor)
      return ""; // Exibe apenas o dia
    } else {
      return "timeGridWeek,timeGridDay"; // Exibe a semana
    }
  };

  // Efeito para atualizar a visualização quando a tela é redimensionada
  useEffect(() => {
    updateViewBasedOnScreenSize(); // Atualiza a visualização na inicialização
    window.addEventListener("resize", updateViewBasedOnScreenSize); // Ouve mudanças de tamanho da tela
    return () => {
      window.removeEventListener("resize", updateViewBasedOnScreenSize); // Limpa o ouvinte quando o componente for desmontado
    };
  }, []);

  // New state variables to store the current visible date range
  const [currentStartDate, setCurrentStartDate] = useState("");
  const [currentEndDate, setCurrentEndDate] = useState("");
  //Fetch shedules
  // Function to fetch availability
  const fetchSchedules = async (startDate, endDate) => {
    try {
      const token = localStorage.getItem("userToken"); // Assumes JWT token is stored in localStorage

      const response = await axios.get(
        path + `sche/GetmySchedules?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request
            "Content-Type": "application/json",
          },
        }
      );

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // Map schedule data to FullCalendar events format
      const schedules = response.data.map((schedule) => ({
        id: schedule.id, // Ensure the event has an id
        title: getByTitle(schedule).title,
        start: formatTZ(toZonedTime(schedule.startDate, timeZone), "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone }),
        end: formatTZ(toZonedTime(schedule.endDate, timeZone), "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone }),
        allDay: false,
        extendedProps: {
          description: getByTitle(schedule).description, // Add the description here
        },
        // Add color properties
        backgroundColor: getBackgroundColor(schedule), // Function to determine the background color
        // borderColor: getBorderColor(schedule), // Optional: Set the border color
        // textColor: getTextColor(schedule), // Optional: Set the text color
      }));

      setschedules(schedules); // Set the events for FullCalendar
      setError(""); // Clear errors
    } catch (error) {
      const message = error.response?.data?.message || "Erro inesperado.";
      console.error("Falha ao agendar o evento:", message);
      setError(
        "Failed to fetch availability. Please check the date range and try again." +
        error
      );
    }
  };

  // Example functions to return colors based on criteria
  const getBackgroundColor = (schedule) => {
    // Example: Assign color based on some property in the schedule (e.g., userId, type)
    if (schedule.associatedwithuserId === null) {
      return "#E5CF5D"; // User 1's schedules are blue
    } else {
      return "#12747D"; // Default color
    }
  };

  // Example functions to return colors based on criteria
  const getByTitle = (schedule) => {
    if (schedule.associatedwithuserId === null) {
      return {
        title: "Por atribuir", // Title for the event
        //description: "Este evento ainda não está atribuído a um anestesista.", // Additional info
      };
    } else {
      return {
        title: `Confirmado com: ${schedule.associatedUser.username}`, // Title for the event
        //description: "Este evento está confirmado.", // Additional info
      };
    }
  };
  const convertTo24HourFormat = (isoString) => {
    const date = new Date(isoString); // Converter ISO string para objeto Date
    
    // Extraímos as horas e minutos
    let hours = date.getHours();
    let minutes = date.getMinutes();
    
    // Certifique-se de que os minutos tenham dois dígitos
    const paddedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    
    // Retorne no formato 24 horas (HH:mm)
    return `${hours}:${paddedMinutes}`;
  };
  
  const fetchBusinessHours = async (startDate, endDate) => {
    try {
      const token = localStorage.getItem("userToken");
  
      const response = await axios.get(path + `avail/Getavailability`, {
        params: { startDate, endDate },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      // Convert business hours' start and end times to 12-hour format
      const formattedBusinessHours = response.data.map((businessHour) => ({
        ...businessHour,
        startTime: convertTo24HourFormat(businessHour.startTime), // Convert to 12-hour format
        endTime: convertTo24HourFormat(businessHour.endTime), // Convert to 12-hour format
      }));
  
      // Log the formatted business hours
      console.log('Formatted Business Hours:', formattedBusinessHours);
  
      // Now you can use these hours with FullCalendar or other components
      // Example: You can now pass these to FullCalendar or use them elsewhere
      // Format for FullCalendar
      const calendarEvents = formattedBusinessHours.map((businessHour) => ({
        title: "Business Hours", // You can set a custom title
        start: businessHour.startTime, // FullCalendar expects the time in 12-hour format
        end: businessHour.endTime,
        daysOfWeek: businessHour.daysOfWeek, // Keep the days of the week
      }));
  
      console.log('FullCalendar Events:', calendarEvents);
  
      setBusinessHours(formattedBusinessHours);
      setIsSchedulesEmpty(formattedBusinessHours.length === 0);
    } catch (error) {
      console.error("Error fetching business hours:", error);
    }
  };
  // Fetch business hours when the date range changes
  const handleDatesSet = (dateInfo) => {
    const startDate = format(new Date(dateInfo.startStr), "yyyy-MM-dd"); // Format start date as YYYY-MM-DD
    const endDate = format(new Date(dateInfo.endStr), "yyyy-MM-dd"); // Format end date as YYYY-MM-DD

    // Update the current visible date range
    setCurrentStartDate(startDate);
    setCurrentEndDate(endDate);

    fetchBusinessHours(startDate, endDate); // Fetch business hours for the new date range
    fetchSchedules(startDate, endDate); // Fetch Schedules for the new date range
  };

  const handleDateSelect = (selectInfo) => {
    const { start, end } = selectInfo;

    // Get the day of the week for the selected date
    const dayOfWeek = start.getDay();

    // Get the allowed hours for the selected day
    const allowedHoursList = businessHours.filter((bh) =>
      bh.daysOfWeek.includes(dayOfWeek)
    );

    const startHour = start.getHours();
    const endHour = end.getHours();

    // Validate selected time against allowed hours
    // Check if the selected time is within any of the allowed hours for the day
    const isWithinAllowedHours = allowedHoursList.some(
      (allowedHours) =>
        startHour >= parseInt(allowedHours.startTime.split(":")[0], 10) &&
        startHour < parseInt(allowedHours.endTime.split(":")[0], 10) &&
        endHour > parseInt(allowedHours.startTime.split(":")[0], 10) &&
        endHour <= parseInt(allowedHours.endTime.split(":")[0], 10)
    );

    if (isWithinAllowedHours) {
      setSelectedRange(selectInfo); // Store the selected date range
      setShowModal(true); // Show the modal
    } else {
      alert(`Por favor selecione uma hora disponivel neste dia.`);
    }
  };

  const handleScheduleform = async (formData) => {
    setlocalformdata(formData);
  };

  const handleSchedule = async () => {
    try {
      const token = localStorage.getItem("userToken"); // Get the JWT token

      // Create the event object
      const newEvent = {
        startDate: selectedRange.start.toISOString(), // Convert date to ISO format
        endDate: selectedRange.end.toISOString(),
      };

      // Combina os dados do evento e do questionário
      const requestData = {
        ...newEvent, // Dados do evento (startDate, endDate)
        ...localformdata, // Dados do questionário (ex: medicalHistory, species, etc.)
      };

      // Envia uma única requisição para salvar o evento e o questionário
      const response = await axios.post(
        path + "sche/CreateScheduleWithQuestionary",
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token for authentication
            "Content-Type": "application/json",
          },
        }
      );

      // Processa a resposta
      const createdScheduleId = response.data.schedule.id; // Obtém o ID da marcação criada
      console.log("Nova marcação criada com ID:", createdScheduleId);

      // Atualiza a lista de horários
      await fetchSchedules(currentStartDate, currentEndDate);

       // If the request is successful, add the new event to the calendar
      setlocalformdata(null);
      setShowModal(false); // Close the modal
      setSelectedRange(null); // Clear the selected range
      setError(""); // Clear any previous errors
    } catch (error) {
      console.error("Falha ao agendar o evento:", error);
      setError(error.response?.data?.message || "Falha ao agendar o evento. Por favor, tente novamente.");
    }
  };

  const isSelectable = () => {
    return businessHours.length > 0; // Calendar is selectable only if there are business hours
  };

  // Handle scheduling Delete
  const handleScheduleDelete = async () => {
    try {
      const token = localStorage.getItem("userToken"); // Assuming the JWT is stored here

      await axios.delete(path + `sche/DeleteSchedule/${currentEvent.id}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token for authentication
          "Content-Type": "application/json",
        },
      });

      // Refresh availability after saving
      await fetchSchedules(currentStartDate, currentEndDate);
      setShowModalDelete(false); // Close the modal
      setCurrentEvent(null);
      setError(""); // Clear any previous errors
    } catch (error) {
      setError("Cannot delete the selected event.");
    }
  };

  // Handle click on a scheduled event
  const handleEventClick = (eventClickInfo) => {
    const { event } = eventClickInfo;
    const { start, end } = event; // Get event details
    setSelectedRange({ start, end }); // Set the selected range
    setShowModalDelete(true); // Show the modal
    setCurrentEvent(event); // Set the current event for modification
  };

  // Function to check if all fields in localformdata are filled
  const isFormComplete = () => {
    return localformdata && !Object.values(localformdata).includes("") && !Object.values(localformdata).includes(0);
  };

  return (
    <Container>
      <h2 className="text-center mb-4 fw-bold textVet">Os meus agendamentos</h2>
      <h5 className="text-center mb-4">
        Só existem Anestesistas disponíveis nos slots permitidos
      </h5>

      {error && <div className="text-danger">{error}</div>}

      <Card>
        <Card.Body>
          <FullCalendar
            plugins={[interactionPlugin, timeGridPlugin]} // Include both plugins
            initialView={updateViewBasedOnScreenSize()}
            locale={ptLocale} // set the locale to Portuguese
            events={schedules}
            selectable={isSelectable()}
            editable={true}
            height="auto"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: updateButtonBasedOnScreenSize(),
            }}
            select={handleDateSelect}
            businessHours={businessHours} // Display business hours on the calendar
            slotDuration="00:30:00" // 30-minute slots for better visibility
            slotLabelInterval="01:00" // Show hour labels
            nowIndicator={true} // Show a line indicating the current time
            slotMinTime="06:00:00" // Set minimum time to 6 AM
            slotMaxTime="19:00:00" // Set maximum time to 7 PM
            allDaySlot={false} // Hide "all-day" slot
            datesSet={handleDatesSet} // Trigger when the visible date range changes
            eventClick={handleEventClick} // Handle event click
            eventContent={(arg) => {
              const description = arg.event.extendedProps.description;
              return (
                <div>
                  <strong>{arg.event.title}</strong>
                  <div style={{ marginTop: "5px" }}>{description}</div>
                </div>
              );
            }}
          />
          {isSchedulesEmpty && (
            <div className="watermark">
              Sem disponibilidade para esta semana
            </div>
          )}
        </Card.Body>
      </Card>

      {/* Modal for scheduling confirmation */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="xl" // Define a largura maior para o modal
      >
        <Modal.Header closeButton>
          <Modal.Title>Marcar um novo evento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div>
              <p style={{ textAlign: "center" }}>
                Deseja agendar um evento de{" "}
                <strong>{selectedRange?.start.toLocaleString()}</strong> a{" "}
                <strong>{selectedRange?.end.toLocaleString()}</strong>?
              </p>
            </div>
            <div>
              <ScheduleQuestionaryForm onSubmit={handleScheduleform} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSchedule} disabled={!isFormComplete()}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for scheduling confirmation */}
      <Modal show={showModalDelete} onHide={() => setShowModalDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Remover disponibilidade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Quer remover a sua disponibilidade de{" "}
            {selectedRange?.start.toLocaleString()} a{" "}
            {selectedRange?.end.toLocaleString()}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalDelete(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleScheduleDelete}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Calendar;
