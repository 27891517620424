// src/components/Home.js
import React, { useEffect, useState } from "react";
import { Container, Button, Modal, Card, CardBody } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import FullCalendar from "@fullcalendar/react"; // FullCalendar React component
import timeGridPlugin from "@fullcalendar/timegrid"; // Plugin for time grid view
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from '@fullcalendar/core/locales/pt'; // Portuguese locale
import { format } from "date-fns"; // Import the date formatting function
import './calendar.css';
import { useNavigate } from "react-router-dom";
import path from "../../config";
import Swal from "sweetalert2"; // Import SweetAlert2


function Home() {
  // State to store user's availability and modal inputs
  const [availabilities, setAvailabilities] = useState([]);
  const [selectedRange, setSelectedRange] = useState(null); // For storing selected date range
  const [showModal, setShowModal] = useState(false); // Modal state
  const [showModalDelete, setShowModalDelete] = useState(false); // Modal state
  const [error, setError] = useState("");
  const [currentEvent, setCurrentEvent] = useState(null); // To store the current event being edited

  const navigate = useNavigate();

  // New state variables to store the current visible date range
  const [currentStartDate, setCurrentStartDate] = useState("");
  const [currentEndDate, setCurrentEndDate] = useState("");

  // Função para determinar a visualização com base no tamanho da tela
  const updateViewBasedOnScreenSize = () => {
    console.log(window.innerWidth);
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {  // Se a largura da tela for menor ou igual a 768px (md ou menor)
      return "timeGridDay";  // Exibe apenas o dia
    } else {
      return "timeGridWeek";  // Exibe a semana
    }
  };
   

   const updateButtonBasedOnScreenSize = () => {
    console.log(window.innerWidth);
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {  // Se a largura da tela for menor ou igual a 768px (md ou menor)
      return "";  // Exibe apenas o dia
    } else {
      return "timeGridWeek,timeGridDay";  // Exibe a semana
    }
  };

  // Efeito para atualizar a visualização quando a tela é redimensionada
  useEffect(() => {
    const fetchUserProfileStatus = async () => {
      try {
        const token = localStorage.getItem("userToken");
        if (!token) {
          throw new Error("Token não encontrado");
        }
    
        // Decodificando o token
        const decodedToken = jwtDecode(token); 
    
        // Verificando o valor de profileCompleted no token
        if (decodedToken.profileCompleted === false) {
          // Fazendo a requisição apenas se profileCompleted for false
          const response = await fetch(path + "user/getusercompleted", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
    
          if (!response.ok) {
            throw new Error("Falha ao verificar status do perfil");
          }
    
          const data = await response.json();
    
          // Verificando novamente o status do perfil, caso precise de garantir a consistência
          if (data.profileCompleted === false) {
            Swal.fire({
              title: "Conclua o seu perfil",
              text: "Conclua o seu perfil para ter total acesso à plataforma",
              icon: "warning",
              confirmButtonText: "Ok",
            }).then(() => {
              navigate("/MyProfile");
            });
          }
        } else {
          // Se o profileCompleted for true, você pode continuar sem fazer a chamada
          console.log("Perfil já completo.");
        }
      } catch (error) {
        console.error("Erro ao verificar o status do perfil:", error);
      }
    };

    fetchUserProfileStatus();
    updateViewBasedOnScreenSize();  // Atualiza a visualização na inicialização
    window.addEventListener("resize", updateViewBasedOnScreenSize); // Ouve mudanças de tamanho da tela
    return () => {
      window.removeEventListener("resize", updateViewBasedOnScreenSize); // Limpa o ouvinte quando o componente for desmontado
    };

  }, []);

  // Function to fetch availability
  const fetchAvailability = async (startDate, endDate) => {
    try {
      const token = localStorage.getItem("userToken"); // Assumes JWT token is stored in localStorage

      const response = await axios.get(path +
        `client/myAvailability?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request
            "Content-Type": "application/json",
          },
        }
      );

      // Map availability data to FullCalendar events format
      const events = response.data.map((availability) => ({
        id: availability.id, // Ensure the event has an id
        title: `Disponível`,
        start: `${availability.startDate}`, // Full date and time
        end: `${availability.endDate}`,
        allDay: false,
      }));

      setAvailabilities(events); // Set the events for FullCalendar
      setError(""); // Clear errors
    } catch (error) {
      setError(
        "Failed to fetch availability. Please check the date range and try again." +
        error
      );
    }
  };
  
  /* // Fetch availability when component mounts
   useEffect(() => {
    fetchAvailability(); // Fetch availability data
  }, []); // Empty dependency array means this runs once on mount */

  // Handle date selection
  const handleDateSelect = (selectInfo) => {
    console.log("Selected Range: ", selectInfo); // Debugging log
    setSelectedRange(selectInfo); // Store the selected date range
    setShowModal(true); // Show the modal
  };

  // Fetch availability when the date range changes
  const handleDatesSet = (dateInfo) => {
    const startDate = format(new Date(dateInfo.startStr), "yyyy-MM-dd"); // Format start date as YYYY-MM-DD
    const endDate = format(new Date(dateInfo.endStr), "yyyy-MM-dd"); // Format end date as YYYY-MM-DD

    // Update the current visible date range
    setCurrentStartDate(startDate);
    setCurrentEndDate(endDate);
    // Fetch availability for the visible range
    fetchAvailability(startDate, endDate);
  };

  // Handle scheduling
  const handleSchedule = async () => {
    try {
      const token = localStorage.getItem("userToken"); // Assuming the JWT is stored here

      await axios.post(path + "client/mySchedule",
        {
          startDate: selectedRange.startStr, // Date in YYYY-MM-DD format
          endDate: selectedRange.endStr,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token for authentication
            "Content-Type": "application/json",
          },
        }
      );

      // Refresh availability after saving
      await fetchAvailability(currentStartDate, currentEndDate);
      setShowModal(false); // Close the modal
      setSelectedRange(null); // Clear selected range
      setError(""); // Clear any previous errors
    } catch (error) {
      setError("Failed to schedule availability. Please try again.");
    }
  };

  // Handle scheduling Delete
  const handleScheduleDelete = async () => {
    try {
      const token = localStorage.getItem("userToken"); // Assuming the JWT is stored here

      await axios.delete(path +
        `client/mySchedule/${currentEvent.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token for authentication
            "Content-Type": "application/json",
          },
        }
      );

      // Refresh availability after saving
      await fetchAvailability(currentStartDate, currentEndDate);
      setShowModalDelete(false); // Close the modal
      setCurrentEvent(null);
      setError(""); // Clear any previous errors
    } catch (error) {
      setError("Cannot delete the selected event.");
    }
  };

  const handleEventClick = (eventClickInfo) => {
    const { event } = eventClickInfo;
    const { start, end } = event; // Get event details
  
    const eventStart = new Date(start);
    const eventEnd = new Date(end);
    const currentDate = new Date();

  
    // Do nothing if the event is in the past
    if (eventEnd < currentDate) {
      return;
    }
  
    // Do nothing if the event is currently happening
    if (eventStart <= currentDate && eventEnd >= currentDate) {
      return;
    }

  
    // Set the selected range and open the modal for deletion
    setSelectedRange({ start, end });
    setShowModalDelete(true);
    setCurrentEvent(event); // Set the current event for modification
  };
  

  return (
    <>
      {/* Fetch My Availability */}
      <Container>
        <h2 className="text-center mb-4 fw-bold textVet">A minha disponibilidade</h2>
        {/* Display Calendar */}
        {error && <Card className="card-spacing"><Card.Body><p className="text-danger">{error}</p></Card.Body></Card>}

        <Card>
          <Card.Body>
            <FullCalendar
              plugins={[interactionPlugin, timeGridPlugin]} // Include both plugins
              initialView={updateViewBasedOnScreenSize()} // Set initial view to week view with time slots
              events={availabilities} // Pass the availability events to FullCalendar
              locale={ptLocale}
              height="auto"
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: updateButtonBasedOnScreenSize(),
              }}
              selectable={true} // Enable selection
              select={handleDateSelect} // Handle date selection
              allDaySlot={false} // Hide "all-day" slot
              slotMinTime={"06:00:00"} // Set minimum time to 6 AM
              slotMaxTime={"19:00:00"} // Set maximum time to 6 PM
              nowIndicator={true} // Optional: highlight the current time
              datesSet={handleDatesSet} // Fetch availability when the view changes
              eventClick={handleEventClick} // Handle event click
            />
          </Card.Body>
        </Card>

        {/* Modal for scheduling confirmation */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Marcar disponibilidade</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Quer registar a sua disponibilidade de{" "}
              {selectedRange?.start.toLocaleString()} a{" "}
              {selectedRange?.end.toLocaleString()}?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleSchedule}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal for scheduling confirmation */}
        <Modal show={showModalDelete} onHide={() => setShowModalDelete(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Remover disponibilidade</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Quer remover a sua disponibilidade de{" "}
              {selectedRange?.start.toLocaleString()} a{" "}
              {selectedRange?.end.toLocaleString()}?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowModalDelete(false)}
            >
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleScheduleDelete}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

export default Home;
