import React, { useState } from "react";
import axios from "axios";
import { FaClipboard } from "react-icons/fa";
import PropTypes from "prop-types";
import path from "../config";

function FileDownloadButton({ eventId }) {
    const [loading, setLoading] = useState(false);
    const fetchAndDownload = async () => {
      if (!eventId) {
        alert("Erro: `eventId` não foi fornecido ou está indefinido.");
        return;
      }
  
      
      setLoading(true);
  
      try {
        // Fetch files associated with the event
        const filesResponse = await axios.get(`${path}files/schedulefiles/${eventId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });
  
        const files = filesResponse.data.files || [];
        if (files.length === 0) {
          alert("Nenhum arquivo encontrado1.");
          setLoading(false); // Reset loading state
          return;
        }
  
        // Find the file with `analgesicPlan === true`
        const fileToDownload = files.find((file) => file.analgesicPlan === true);
        if (!fileToDownload) {
          alert("Nenhum arquivo de plano analgésico disponível para download.");
          setLoading(false); // Reset loading state
          return;
        }
  
        // Download the file
        const response = await axios.get(`${path}files/getFileById/${fileToDownload.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
          responseType: "blob",
        });
  
        // Create a link for downloading the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileToDownload.OriginalName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error("Erro durante o processo de download:", error);
        alert("Erro ao realizar o download do arquivo.");
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <div onClick={fetchAndDownload} style={{ cursor: "pointer" }}>
        <FaClipboard
          className={`mx-1 text-success ${loading ? "disabled" : ""}`}
          title="Revisão"
          size={24}
        />
      </div>
    );
  }

FileDownloadButton.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default FileDownloadButton;
