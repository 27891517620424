import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Button, Form, Alert, Spinner, Modal } from "react-bootstrap";
import { jwtDecode } from "jwt-decode"; // Import jwtDecode to decode the token
import { FaPlus, FaTrash } from "react-icons/fa"; // Import Plus and Trash icons
import path from "../config";

const ScheduleNotes = ({ scheduleId }) => {
  const [notes, setNotes] = useState([]); // State to store notes
  const [newNote, setNewNote] = useState(""); // State to store new note content
  const [loading, setLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null); // State for errors
  const [success, setSuccess] = useState(null); // State for success messages
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal
  const [noteToDelete, setNoteToDelete] = useState(null); // State to store the note to delete
  const maxCharacters = 2000; // Character limit

  // Get current user's information from token
  const token = localStorage.getItem("userToken");
  const decodedToken = jwtDecode(token);
  const currentUserId = decodedToken.id; // Adjust according to your token structure


  const fetchNotes = async () => {
    try {
      const response = await axios.get(
        path + `notes/GetNotesByScheduleId/${scheduleId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      setNotes(response.data); // Update notes in state
    } catch (error) {
      console.error("Erro ao ler notas:", error);
      setError("Erro ao ler notas.");
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [scheduleId]);

  const handleCreateNote = async (e) => {
    e.preventDefault();
    if (!newNote) return;

    try {
      await axios.post(
        path + "notes/CreateNote",
        {
          Notes: newNote,
          ScheduleId: scheduleId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      setNewNote("");
      setSuccess("Nota adicionada com sucesso.");
      setError(null);
      await fetchNotes();
      setShowModal(false);
    } catch (error) {
      console.error("Erro ao adicionar nova nota:", error);
      setError("Erro ao adicionar nova nota.");
      setSuccess(null);
    }
  };

  const handleDeleteNote = async (noteId) => {
    try {
      await axios.delete(path + `notes/DeleteNote/${noteId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      setSuccess("Nota apagada com sucesso.");
      await fetchNotes(); // Refresh notes after deletion
    } catch (error) {
      console.error("Erro ao apagar nota:", error);
      setError("Erro ao apagar nota.");
    } finally {
      setShowDeleteModal(false); // Close delete modal
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setNewNote("");
  };

  const handleShowDeleteModal = (note) => {
    setNoteToDelete(note);
    setShowDeleteModal(true);
  };
  
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  if (loading) return <Spinner animation="border" role="status" className="mt-5" />;

  return (
    <div className="container">
      <h2 className="mb-4">
        Notas
        <FaPlus
          style={{ cursor: "pointer", marginLeft: "10px" }}
          onClick={handleShowModal}
          className="dscolor"
        />
      </h2>

      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}

      {/* Modal for adding a new note */}
      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Adicionar nova nota</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleCreateNote}>
            <Form.Group controlId="newNote">
              <Form.Label>Escreva sua nota</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                placeholder="Escreva a sua nota aqui..."
                maxLength={maxCharacters}
              />
              <div className="d-flex justify-content-end">
                <small className="text-muted">
                  {newNote.length}/{maxCharacters} caracteres
                </small>
              </div>
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit" className="mt-2">
                Adicionar nota
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal for confirming deletion */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmação de exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza de que deseja apagar esta nota?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancelar
          </Button>
          <Button 
            variant="danger" 
            onClick={() => noteToDelete && handleDeleteNote(noteToDelete.id)}
          >
            Apagar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="notes-container">
        {notes.length > 0 ? (
          notes.map((note) => (
            <Card key={note.id} className="mb-2">
              <Card.Body>
                <strong>{note.creator.username}:</strong>
                <p className="break-word">{note.Notes}</p>
                <small className="text-muted">
                  {new Date(note.InsertedOn).toLocaleString()}
                </small>
                {/* Render the trash icon only if the current user is the creator of the note */}
                {note.creator.id === currentUserId && ( // Adjust based on how you store user ID
                  <FaTrash 
                    style={{ cursor: "pointer",float: "right", color: "red" }}
                    onClick={() => handleShowDeleteModal(note)} 
                  />
                )}
              </Card.Body>
            </Card>
          ))
        ) : (
          <Card className="mb-2">
            <Card.Body>Sem notas disponíveis.</Card.Body>
          </Card>
        )}
      </div>
    </div>
    
  );
};

export default ScheduleNotes;
