import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Spinner, Button } from "react-bootstrap";
import { jwtDecode } from "jwt-decode"; // Import jwtDecode para decodificar o token
import path from "../config";

const ScheduleNotes = ({ scheduleId }) => {
    const [notes, setNotes] = useState([]); // Inicializa o estado como um array vazio
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [notesPerPage] = useState(2); // Quantidade de notas por página
    const [totalPages, setTotalPages] = useState(1); // Total de páginas

    const token = localStorage.getItem("userToken");
    const decodedToken = jwtDecode(token);
    const currentUserId = decodedToken.id;

    const fetchNotes = async () => {
        try {
            const response = await axios.get(
                path + `notes/GetNotesByScheduleId/${scheduleId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                    },
                }
            );

            // Verifique a estrutura da resposta com o log
            console.log(response.data);

            if (Array.isArray(response.data)) {
                setNotes(response.data); // Atualiza o estado de notas
                setTotalPages(Math.ceil(response.data.length / notesPerPage)); // Atualiza o total de páginas
            } else {
                setNotes([]); // Caso a resposta não seja um array, inicializa como um array vazio
                setTotalPages(1); // Se não tiver notas, ainda há uma página
            }

        } catch (error) {
            console.error("Erro ao ler notas:", error);
            setError("Erro ao ler notas.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNotes();
    }, [scheduleId]);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Pega as notas para a página atual
    const indexOfLastNote = currentPage * notesPerPage;
    const indexOfFirstNote = indexOfLastNote - notesPerPage;
    const currentNotes = notes.slice(indexOfFirstNote, indexOfLastNote);

    // Verificar se o estado de notas está correto
    if (loading) return <Spinner animation="border" role="status" className="mt-5" />;

    return (
        <div>
            <h2>Notas</h2>
            <div>
                {Array.isArray(currentNotes) && currentNotes.length > 0 ? (
                    currentNotes.map((note) => (
                        <Card key={note.id} className="mb-2 position-relative">
                            <Card.Body className="p-2">
                                <div className="d-flex justify-content-between">
                                    <strong>{note.creator.username}:</strong>
                                </div>
                                <p className="break-word">{note.Notes}</p>
                                <small
                                    className="text-muted position-absolute"
                                    style={{ top: "10px", right: "10px" }}
                                >
                                    {new Date(note.InsertedOn).toLocaleString()}
                                </small>
                            </Card.Body>
                        </Card>
                    ))
                ) : (
                    <Card>
                        <Card.Body className="p-2">Sem notas disponíveis.</Card.Body>
                    </Card>
                )}
            </div>

            <div className="d-flex justify-content-between mt-4">
                <Button
                    variant="primary"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                >
                    Anterior
                </Button>
                <span>
                    Página {currentPage} de {totalPages}
                </span>
                <Button
                    variant="primary"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Próxima
                </Button>
            </div>
        </div>
    );
};

export default ScheduleNotes;
